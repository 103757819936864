
import React from "react";
import "./SimilarCompanies.css";
// Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
// Import Images
import building from "../../images/building.jpg"

interface SimilarCompaniesProps {
    setActiveMainIndex: (index: number) => void;
}

const SimilarCompanies: React.FC<SimilarCompaniesProps> = ({ setActiveMainIndex }) => {
    const handleSeeMoreClick = () => {
        setActiveMainIndex(4); // Set the main index to 4 for SimilarCompaniesWrapper
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    }

    const MainPageSimilarCompany = [
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
        {
            img: building, h4: "საქართველოს ბანკი",
            h6: {
                text: "ნახვა",
                icon: faDownLeftAndUpRightToCenter,
                style: { transform: "translateX(-3px)" }
            }
        },
    ]

    return (
        <div className="BoxContainerForSimilar">
            <div className="SimCompany">
                <h4 className="SimilarCompany-Tit">მსგავსი კომპანიები</h4>
            </div>
            <div className="SeeMoreSimilar">
                <h5 className="SeeSimilar" onClick={handleSeeMoreClick}>მეტის ნახვა</h5>
            </div>

            <div className="AllContent">

                {MainPageSimilarCompany.map((company, index) => (
                    <div key={index} className="BOGSimilar">
                        <img src={company.img} alt="Building icon in circle" className="BuildingInCirlce" />
                        <h4 className="BogTextSimilar">{company.h4}</h4>
                        <h6 className="SeeBog">
                            <span>
                                <FontAwesomeIcon icon={company.h6.icon} style={company.h6.style} />
                                {company.h6.text}
                            </span>
                        </h6>
                    </div>
                ))}
            </div>
            {/* finish */}
        </div>
    )
}

export default SimilarCompanies;
