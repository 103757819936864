import React, { useState, useEffect } from 'react';
import './MainPageNews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Define types for the data structure
interface NewsImage {
    boxed: string;
    boxed_thumbnail: string;
    wide: string;
    wide_thumbnail: string;
}

interface NewsItem {
    id: number;
    images: NewsImage;
    publish_date: string;
    title: string;
    url: string;
}

// Utility function to truncate the title
const truncateTitle = (title: string, maxLength: number): string => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
};

function MainPageNews() {
    const [newsData, setNewsData] = useState<NewsItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const BackendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Simulate a delay to show the loader
        setTimeout(() => {
            fetch(`${BackendUrl}/bm_ge/get-all-news`)
                .then(response => response.json())
                .then(data => {
                    setNewsData(data.data.slice(0, 5)); // Limit to 5 items
                    setLoading(false);
                })
                .catch(error => console.error('Error fetching news:', error));
        }, 1000); // Simulate a 1-second delay
    }, []);

    const handleNewsClick = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <div>
            <div className="MainContainer-landingpage">


                <div className="CompanyNews">
                    <h4 className='PartTitle'>სიახლეები</h4>
                </div>

                <div className="AllContent-landingpage">
                    {loading ? (
                        Array.from({ length: 5 }).map((_, index) => ( // Render 5 skeletons
                            <div key={index} className="MainNews skeleton">
                                <div className="skeleton-img"></div>
                                <h5 className="skeleton-title"></h5>
                                <h5 className="skeleton-media"></h5>
                                <h5 className="skeleton-date"></h5>
                                <h5 className="skeleton-full"></h5>
                            </div>
                        ))
                    ) : (
                        newsData.map((newsItem) => (
                            <div
                                key={newsItem.id}
                                className="MainNews"
                                onClick={() => handleNewsClick(newsItem.url)}
                            >
                                <img
                                    src={newsItem.images.boxed_thumbnail}
                                    alt={newsItem.title}
                                    className='ContainerImage'
                                />
                                <h5 className='NewsTitle'>
                                    {truncateTitle(newsItem.title, 60)}
                                </h5>
                                <h5 className="Media-landingpage">Business Media</h5>
                                <h5 className='TimeAndDate'>{new Date(newsItem.publish_date).toLocaleDateString('ka-GE', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                })}</h5>
                                <h5 className='Full'>ვრცლად <span className='fonticon'><FontAwesomeIcon icon={faChevronRight} style={{ transform: "translateY(2px)" }} /></span></h5>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default MainPageNews;
