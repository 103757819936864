import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ChartStyle.css';
import Financial from './Financial';
import { FinancialMetric } from './Financial';
import BarChartFetcher from './BarChartFetcher';
import DoubleChartFetcher from './DoubleChartFetcher';
import DivisionResultChartFetcher from './DivisionResultChartFetcher';
import './FinansuriMachveneblebi.css';

const DataFetcher: React.FC = () => {
  const { idCode } = useParams<{ idCode: string }>();
  const [financialMetrics, setFinancialMetrics] = useState<FinancialMetric[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('2022');
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const cacheKey = `financialMetrics_${idCode}_${selectedYear}`;
    
    // Check if data is already cached
    const cachedData = sessionStorage.getItem(cacheKey);

    if (cachedData) {
      // If cached data exists, parse and use it
      setFinancialMetrics(JSON.parse(cachedData));
    } else {
      // Fetch data from the API if not cached
      axios
        .get(`${BackendUrl}/finansuri_machveneblebi/financial_highlights/${idCode}?year=${selectedYear}`)
        .then(response => {
          setFinancialMetrics(response.data.data);
          // Cache the data
          sessionStorage.setItem(cacheKey, JSON.stringify(response.data.data));
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    }
  }, [idCode, selectedYear, BackendUrl]);

  return (
    <div>
      <div className='chart-container'>
        <h2 className='Finansurimachveneblebi'>ფინანსური მაჩვენებლები</h2>
        <h4>განახლდა 15 მარ. 2024</h4>
        <div className="charts-row">
          <BarChartFetcher />
          <DoubleChartFetcher />
          <DivisionResultChartFetcher />
        </div>
      </div>
      <div className='FinancialMachveneblebi'>
        <Financial data={financialMetrics} selectedYear={selectedYear} onYearChange={setSelectedYear} />
      </div>
    </div>
  );
}

export default DataFetcher;
