
import "./ChartUnion.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from "react";
// Import Chart
import FirstChart from "./FirstChart";
import SecondChart from "./SecondChart";
import FinancialChart from "./FinancialCont";
import FinancState from "./FinancialStatement";
import SimilarCompanies from "../similarCompaniesNew/SearchRes";
import NewS from "../NewsNew/News";
import BarChart from "../FinansuriMachveneblebi/MtlianiAqtivebi";
import DoubleChart from "../FinansuriMachveneblebi/DoubleChart";
import BarChartFetcher from "../FinansuriMachveneblebi/BarChartFetcher";
import DoubleChartFetcher from "../FinansuriMachveneblebi/DoubleChartFetcher";
import LastView from "../LastView/LastView";
import Recomendation from "../Recomendation/Recomendation";
import Reminder from "../Authorization-Reminder/Reminder";
import { transition } from "d3";
import { Transform } from "@mui/icons-material";

interface ChartUnionProps {
  setActiveMainIndex: (index: number) => void;
  setActiveSubIndex: (index: number) => void;
}

const ChartUnion: React.FC<ChartUnionProps> = ({ setActiveMainIndex, setActiveSubIndex }) => {
  const handleSeeMoreClick = () => {
    setActiveMainIndex(2); // Set the main index to 2 for FinanzuriMachveneblebi
  };

  const [tokenAvailable, setTokenAvailable] = useState<boolean>(false);


  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenAvailable(!!token);
  }, []);

  return (
    <div>
      <div className="ChartUnion-Container">
        <div className="FinancialTitle">
          <h3 className="TitleFinac">ფინანსური მაჩვენებლები</h3>
        </div>
        <div className="Seemore">
          <h5 className="SeeMore-Financial" onClick={handleSeeMoreClick}>მეტის ნახვა</h5>
        </div>
        {/* ჩარტების განლაგება */}
        <div className="ChartUnion-Context">
          <BarChartFetcher />
          <DoubleChartFetcher />
          <FinancialChart />
          {/* esaa is ori patara chart */}
        </div>
      </div>
      <FinancState setActiveMainIndex={setActiveMainIndex} setActiveSubIndex={setActiveSubIndex} />
      <SimilarCompanies setActiveMainIndex={setActiveMainIndex} />
      <NewS setActiveMainIndex={setActiveMainIndex} />
      {/* <NewS setActiveMainIndex={setActiveMainIndex} /> */}
      <div className="mainPageWrapperSecond">
        {tokenAvailable ? (
          <>
            <LastView />
            <Recomendation />
          </>
        ) : (
          <div className="reminder">
            <Reminder />
          </div>
        )}
      </div>
    </div >
  );
};
export default ChartUnion;
