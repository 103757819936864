import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DoubleChart, { DoubleChartData } from './DoubleChart';
import './ChartStyle.css';

const DoubleChartFetcher: React.FC = () => {
  const { idCode } = useParams<{ idCode: string }>();
  const [data, setData] = useState<DoubleChartData | null>(null);
  const [loading, setLoading] = useState(true);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Check if data is already cached
    const cachedData = sessionStorage.getItem(`doubleChartData_${idCode}`);

    if (cachedData) {
      // If cached data exists, parse and use it
      setData(JSON.parse(cachedData));
      setLoading(false);
    } else {
      // Fetch data from the API if not cached
      axios.get(`${BackendUrl}/finansuri_machveneblebi/shemosavlebi_marja/${idCode}`)
        .then(response => {
          setData(response.data);
          sessionStorage.setItem(`doubleChartData_${idCode}`, JSON.stringify(response.data)); // Cache the data
          setLoading(false);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
          setLoading(false);
        });
    }
  }, [idCode, BackendUrl]);

  return (
    <div className='bar-chart-wrapper'>
      <h3 className='chart-title1'>მთლიანი შემოსავლები</h3>
      <h3 className='chart-title2'>მთლიანი მოგების მარჟა</h3>
      <br />
      {loading ? (
        <div className='DoubleChartloader'></div>
      ) : (
        data && <DoubleChart data={data} />
      )}
    </div>
  );
}

export default DoubleChartFetcher;
