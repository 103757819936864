import React from "react";
import { useNavigate } from "react-router-dom";
import "./SearchResultt.css";
import building from "../../images/Building.png";
import { jwtDecode, JwtPayload } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  user_id: string;
}

interface SearchResult {
  id: number;
  id_code: number;
  name: string;
  Organization: string;
  LatestValues: {
    [key: string]: [number, number];
  };
  PercentageDifferences: {
    [key: string]: number;
  };
  PreviousValues: {
    [key: string]: [number, number];
  };
}

interface SearchingResultProps {
  searchResults: SearchResult[];
}

function SearchingResult({ searchResults }: SearchingResultProps) {
  const history = useNavigate(); // Get history for navigation

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-US').format(num);
  };

  const AuthUrl = process.env.REACT_APP_AUTHAPI_URL;

  const saveCompany = async (id: number, name: string) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode<CustomJwtPayload>(token);
        const response = await fetch(`${AuthUrl}/save_private_page_saved_companies`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            userId: decodedToken.user_id,
            idCode: id,
            OrgNameInReport: name
          })
        });

        const data = await response.json();
        if (response.ok) {
          console.log('Company saved successfully:', data.message);
        } else {
          console.error(data.error);
        }
      } else {
        console.error('No JWT token found in localStorage');
      }
    } catch (error) {
      console.error('Error saving company:', error);
    }
  };

  const handleResultClick = (id_code: number) => {
    console.log(id_code);
    history(`/company/${id_code}`);
  };

  return (
    <div className="BoxContainers-Search">
      <div className="navbarContent-SearchComp">

        <div className="List">
          <div className="FullActive-Search">
            <h5 className="Active-Search">მთლიანი აქტივები</h5>
          </div>

          <div className="Responsible-Search">
            <h5 className="FullResponsible-Search"><span className="breakAfter">მთლიანი</span>ვალდებულებები</h5>
          </div>

          <div className="FullCapital-Search">
            <h5 className="Capital-Search">მთლიანი კაპიტალი</h5>
          </div>
          <div className="FullIncome-Search">
            <h5 className="Income-Search"><span className="breakAfter">მთლიანი</span>შემოსავალი</h5>
          </div>
          <div className="ClearWin-Search">
            <h5 className="Win-Search">წმინდა მოგება</h5>
          </div>

        </div>

        <div className="SearchingResult-SearchComp">
          <h3 className="Search-Sim">ძიების შედეგები</h3>
          <h4 className="Name-Search">დასახელება</h4>
        </div>



        {searchResults.map(result => {
          const totalAssets = result.LatestValues['სულ აქტივები'];
          const totalLiabilities = result.LatestValues['სულ ვალდებულებები'];
          const totalCapital = result.LatestValues['სულ კაპიტალი'];
          const totalIncome = result.LatestValues['სულ შემოსავალი'];
          const netProfit = result.LatestValues['წმინდა მოგება'];

          const totalAssetsPercentage = result.PercentageDifferences['სულ აქტივები'];
          const totalLiabilitiesPercentage = result.PercentageDifferences['სულ ვალდებულებები'];
          const totalCapitalPercentage = result.PercentageDifferences['სულ კაპიტალი'];
          const totalIncomePercentage = result.PercentageDifferences['სულ შემოსავალი'];
          const netProfitPercentage = result.PercentageDifferences['წმინდა მოგება'];

          return (
            <div
              key={result.id}
              onClick={() => handleResultClick(result.id_code)}
              style={{ cursor: 'pointer' }}
              className="search-result-wrapper"
            >
              <div className="HorizontalLineXBog">
                <div className="CompanyContext">
                  <img src={building} className="BogImg" alt="Company logo"></img>
                  <h4 className="SearchingCompany">{result.Organization}</h4>
                  <h6 className="SeeCompanySearch">
                    <span className="CustomIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" className="upArrow" fill="currentColor">
                        <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
                      </svg>
                    </span>
                    ნახვა
                  </h6>
                </div>
              </div>

              <div className="ActiveNumber">
                <h4 className="NumForSearch">{totalAssets ? formatNumber(totalAssets[0]) : 'N/A'}</h4>
                <h4 className={`PercentSearch ${totalAssetsPercentage < 0 ? 'PercentRed' : 'PercentGreen'}`}>
                  {totalAssetsPercentage !== undefined ? `${totalAssetsPercentage}%` : 'N/A'}
                </h4>
              </div>
              <div className="ActiveNumber2">
                <h4 className="NumForSearch">{totalLiabilities ? formatNumber(totalLiabilities[0]) : 'N/A'}</h4>
                <h4 className={`PercentSearch ${totalLiabilitiesPercentage < 0 ? 'PercentRed' : 'PercentGreen'}`}>
                  {totalLiabilitiesPercentage !== undefined ? `${totalLiabilitiesPercentage}%` : 'N/A'}
                </h4>
              </div>
              <div className="RestOfAllNumber">
                <h4 className="Num">{formatNumber(1444405)}</h4>
                <h4 className="PercentGreenSearch">4.4%</h4>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
              </div>
              <div className="RestOfAllNumber">
                <h4 className="Num">{formatNumber(1444405)}</h4>
                <h4 className="PercentGreenSearch">4.4%</h4>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
              </div>
              <div className="RestOfAllNumber">
                <h4 className="Num">{formatNumber(1444405)}</h4>
                <h4 className="PercentGreenSearch">4.4%</h4>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
              </div>
              {/* <div className="Compere-Saving">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" fill="rgba(101, 106, 249, 1)"><path d="M16.0503 12.0498L21 16.9996L16.0503 21.9493L14.636 20.5351L17.172 17.9988L4 17.9996V15.9996L17.172 15.9988L14.636 13.464L16.0503 12.0498ZM7.94975 2.0498L9.36396 3.46402L6.828 5.9988L20 5.99955V7.99955L6.828 7.9988L9.36396 10.5351L7.94975 11.9493L3 6.99955L7.94975 2.0498Z"></path></svg>
                <h4 className="CompereNavbar">შედარება</h4>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23" className="PlusNavbar" fill="rgba(101, 106, 249, 1)"><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                <h4 className="SavingNavbar pointer-cursor" onClick={(e) => { e.stopPropagation(); saveCompany(result.id_code, result.Organization); }}>შენახვა</h4>
              </div> */}
            </div>
          );
        })}
      </div>

    </div>

  );
}

export default SearchingResult;
