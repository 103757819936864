import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface DataItem {
    FVYear: number;
    LineItemGEO: string;
    value_gel: number;
}

interface DivisionResult {
    FVYear: number;
    division_result: number;
}

export interface DoubleChartData {
    dataNeto: DataItem[];
    dataSaerto: DataItem[];
    division_results: DivisionResult[];
}

interface DoubleChartProps {
    data: DoubleChartData;
}

const DoubleChart: React.FC<DoubleChartProps> = ({ data }) => {
    const d3Container = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        if (data.dataSaerto && d3Container.current) {
            const svg = d3.select(d3Container.current);

            svg.selectAll("*").remove(); // Clear the SVG

            const margin = { top: 20, right: 30, bottom: 70, left: 70 };
            const width = 420 - margin.left - margin.right;
            const height = 350 - margin.top - margin.bottom;

            const chartArea = svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            chartArea.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "#f1f6ff");

            // Sort dataSaerto in descending order based on FVYear
            const sortedDataSaerto = [...data.dataSaerto].sort((a, b) => b.FVYear - a.FVYear);

            const x = d3.scaleBand()
                .range([0, width])
                .domain(sortedDataSaerto.map(d => d.FVYear.toString()))
                .padding(0.65);

            const y = d3.scaleLinear()
                .domain([0, d3.max(sortedDataSaerto, d => d.value_gel) as number])
                .range([height, 0]);

            chartArea.selectAll(".barSaerto")
                .data(sortedDataSaerto)
                .enter()
                .append("rect")
                .attr("class", "barSaerto")
                .attr("x", d => x(d.FVYear.toString()) as number)
                .attr("y", d => y(d.value_gel))
                .attr("width", x.bandwidth())
                .attr("height", d => height - y(d.value_gel))
                .attr("fill", "#FF9012")
                .attr("ry", 5);

            chartArea.selectAll(".barSaertoText")
                .data(sortedDataSaerto)
                .enter()
                .append("text")
                .attr("class", "barSaertoText")
                .attr("x", d => (x(d.FVYear.toString()) as number) + x.bandwidth() / 2) // Center the text in the bar
                .attr("y", d => y(d.value_gel) - 5) // Position above the bar
                .attr("text-anchor", "middle") // Center the text horizontally
                // .text(d => `${d.value_gel.toLocaleString()}`) // Use toLocaleString() for better readability
                .text(d => {
                    // Find the corresponding division result
                    const divisionResult = data.division_results.find(result => result.FVYear === d.FVYear);
                    // Calculate the complementary percentage
                    const complementaryPercentage = divisionResult ? 100 - (divisionResult.division_result * 100) : 100;
                    // Format the string to show as a percentage with one decimal place
                    return `${complementaryPercentage.toFixed(1)}%`;
                })
                .attr("font-size", "14px")
                .attr("fill", "#333");

            chartArea.selectAll(".barDivisionResult")
                .data(data.division_results)
                .enter()
                .append("rect")
                .attr("class", "barDivisionResult")
                .attr("x", d => x(d.FVYear.toString()) as number)
                .attr("y", d => {
                    const item = sortedDataSaerto.find(item => item.FVYear === d.FVYear);
                    return item ? y(item.value_gel * d.division_result) : 0;
                })
                .attr("width", x.bandwidth())
                .attr("height", d => {
                    const item = sortedDataSaerto.find(item => item.FVYear === d.FVYear);
                    return item ? height - y(item.value_gel * d.division_result) : 0;
                })
                .attr("fill", "#656af9")
                .attr("ry", 5);

            chartArea.selectAll(".barDivisionResultText")
                .data(data.division_results)
                .enter()
                .append("text")
                .attr("class", "barDivisionResultText")
                .attr("x", d => (x(d.FVYear.toString()) as number) + x.bandwidth() / 2) // Center the text in the bar
                .attr("y", d => {
                    const item = sortedDataSaerto.find(item => item.FVYear === d.FVYear);
                    return item ? y(item.value_gel * d.division_result) + 15 : 0; // Position slightly within the division result bar
                })
                .attr("text-anchor", "middle") // Center the text horizontally
                .text(d => `${(d.division_result * 100).toFixed(1)}%`) // Convert to percentage and format
                .attr("font-size", "14px")
                .attr("fill", "white");

            chartArea.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x))
                .selectAll("text")
                .style("font-family", "AvenirNextGeorgianRegular")
                .style("font-size", "12px")
                .style("fill", "#333");

            chartArea.append("g")
                .call(d3.axisLeft(y).ticks(6).tickFormat((d) => {
                    const value = +d;
                    return value >= 1e6 ? `${value / 1e6}მლნ.` : `${value / 1e3}ათ.`;
                }))
                .selectAll("text")
                .style("font-family", "AvenirNextGeorgianRegular")
                .style("font-size", "12px")
                .style("fill", "#333");

            const tooltip = chartArea.append("text")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .attr("text-anchor", "middle")
                .attr("fill", "#000")
                .attr("font-size", "12px");

            chartArea.selectAll(".barDivisionResult")
                .data(data.division_results)
                .enter()
                .append("rect")
                .attr("class", "barDivisionResult")
                // Rest of your bar drawing code...
        }
    }, [data]);

    return (
        <svg ref={d3Container} width="460" height="400"></svg>
    );
};

export default DoubleChart;
