import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import "./Compare.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import BasicTable from "./Custom";
import axios from "axios";

interface Suggestion {
    id: number;
    name: string;
}

function Compare() {
    const [query, setQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);  // Add this line
    const BackendUrl = process.env.REACT_APP_BACKEND_URL

    const fetchSuggestions = async (inputValue: string): Promise<void> => {
        if (inputValue.length < 3) {
            setSuggestions([]);
            return;
        }
        try {
            setLoading(true);
            const response = await axios.get<Suggestion[]>(`${BackendUrl}/search?query=${inputValue}`);
            setSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const debounce = <F extends (...args: any[]) => any>(func: F, wait: number): ((...args: Parameters<F>) => void) => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        return (...args: Parameters<F>) => {
            const later = () => {
                timeout = null;
                func(...args);
            };
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(later, wait);
        };
    };

    const fetchSuggestionsDebounced = useCallback(debounce(fetchSuggestions, 1000), []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setQuery(inputValue);
        fetchSuggestionsDebounced(inputValue);
    };

    const handleSelectSuggestion = (id: number) => {
        setSelectedId(id);
        setDropdownOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (!target.closest('.Compeare-SearchInput')) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="Main-ContainerCompere">
            <div className="First-Container-Compere">
                <div className="Compere">
                    <h3 className="CompereTitle">შედარება</h3>
                </div>
                <div className="Font-Icon-X">
                </div>
                <div className="Compeare-SearchInput" onClick={toggleDropdown}>
                    <span className="Circle-Plus">
                    </span>
                    <input
                        type="Search"
                        className="CompereSearch"
                        placeholder="შეადარე სხვა კომპანიას"
                        value={query}
                        onChange={handleChange}
                    ></input>
                    {loading && <div className="loading" style={{marginRight:'30px', color:'silver'}}>იტვირთება...</div>}
                    {dropdownOpen && suggestions.length > 0 && (
                          <ul className="suggestions-dropdown">
                        {suggestions.map(suggestion => (
                            <li key={suggestion.id} onClick={() => handleSelectSuggestion(suggestion.id)}>
                                {suggestion.name}
                            </li>
                        ))}
                    </ul>
                    )}
                </div>
                <select name="ChooseYear" className="ChooseYear-Compere">
                    <option value="Title">აირჩიე წელი</option>
                    <option value="year1">2022</option>
                    <option value="Year2">2021</option>
                </select>
            </div>
            <BasicTable selectedId = {selectedId}/>
        </div>
    );
}

export default Compare;
