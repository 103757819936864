import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export interface FinancialData {
    FVYear: number;
    LineItemGEO: string;
    value_gel: number;
}

interface BarChartProps {
    data: FinancialData[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const d3Container = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        if (data && d3Container.current) {
            const svg = d3.select(d3Container.current);

            svg.selectAll("*").remove(); // Clear the SVG

            const margin = { top: 20, right: 30, bottom: 45, left: 70 };
            const width = 420 - margin.left - margin.right;
            const height = 350 - margin.top - margin.bottom;

            const chartArea = svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            chartArea.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "#f1f6ff");

            const x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(d => d.FVYear.toString()))
                .padding(0.65);

            chartArea.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x))
                .selectAll("text")
                .style("font-family", "AvenirNextGeorgianRegular")
                .style("font-size", "12px")
                .style("fill", "#333");

            const y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.value_gel) as number])
                .range([height, 0]);

            chartArea.append("g")
                .call(d3.axisLeft(y).ticks(6).tickFormat(d => {
                    const value = +d; 
                    // return value >= 1e6 ? `${value / 1e6}მლნ.` : `${value / 1e3}ათ.`; // Format with "მლნ." and "ათ."
                    return value >= 1e9 
                        ? `${value / 1e9}მლრდ.` 
                        : value >= 1e6 
                        ? `${value / 1e6}მლნ.` 
                        : `${value / 1e3}ათ.`;

                }))
                .selectAll("text")
                .style("font-family", "AvenirNextGeorgianRegular")
                .style("font-size", "12px")
                .style("fill", "#333");

            // Add bars
            chartArea.selectAll(".bar")
                .data(data)
                .enter()
                .append("rect")
                .attr("class", "bar")
                .attr("x", d => x(d.FVYear.toString()) as number)
                .attr("y", d => y(d.value_gel))
                .attr("width", x.bandwidth())
                .attr("height", d => height - y(d.value_gel))
                .attr("fill", "#656af9")
                .attr("ry", 5);

            // Add text labels for each bar
            chartArea.selectAll(".label")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "label")
                .attr("x", d => (x(d.FVYear.toString()) ?? 0) + x.bandwidth() / 2)
                .attr("y", d => y(d.value_gel) - 5)
                .attr("text-anchor", "middle")
                .text(d => d3.format(",.0f")(d.value_gel)) // Format the value with commas
                .style("font-size", "13px")
                .style("fill", "#333");
        }
    }, [data]);

    return (
        <svg ref={d3Container} width="460" height="400"/>
    );
};

export default BarChart;
