import React, { useState, useEffect, useRef } from 'react';
import './SecondNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';
import Authorization from '../Authorization/Authorization';
import MyComponent from '../PersonalPage/Mycomponent'; // Assuming MyComponent is located here

function SecNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthVisible, setIsAuthVisible] = useState(false);
  const [showProfilePage, setShowProfilePage] = useState(false); // State to manage profile page visibility
  const profilePageRef = useRef<HTMLDivElement>(null); // Ref for the profile page

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleBackClick = () => {
    window.location.href = '/';
  };

  const handleAuthClick = () => {
    setIsAuthVisible(true);
  };

  const closeModal = () => {
    setIsAuthVisible(false);
  };

  const handleProfileClick = () => {
    setShowProfilePage(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (profilePageRef.current && !profilePageRef.current.contains(event.target as Node)) {
      setShowProfilePage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="BoxContainer">
      <div className="navbar2">
        <div className="navbarContainer">
          <div className="returnText" onClick={handleBackClick}>
            <h4 className="Same">
              <span className="Span">
                <FontAwesomeIcon icon={faArrowLeft} style={{ transform: "translateY(1px) translateX(-5px)" }} />
              </span>
              უკან დაბრუნება
            </h4>
          </div>

          <div className="Hotline">
            <h4 className="Same">
              <span className="Span">
                <FontAwesomeIcon icon={faPhone} style={{ transform: "translateX(-6px)" }} />
              </span>
              ცხელი ხაზი 16 111
            </h4>
          </div>

          <div className="PrivatePage">
            {isLoggedIn ? (
              <h4 className="Same" onClick={handleProfileClick}>
                <span className="Span">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor" className='PrivatePageIcon'><path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"></path></svg>
                </span>
                პირადი გვერდი
              </h4>
            ) : (
              <h4 className="Same" onClick={handleAuthClick}>
                <span className="Span">
                  <FontAwesomeIcon icon={faUser} style={{ transform: "translateX(-6px)" }} />
                </span>
                ავტორიზაცია
              </h4>
            )}
          </div>
        </div>
      </div>

      {isAuthVisible && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <Authorization />
          </div>
        </div>
      )}

      {showProfilePage && (
        <div className="my-component-overlay" ref={profilePageRef} onClick={e => e.stopPropagation()}>
          <MyComponent setShowProfilePage={setShowProfilePage} />
        </div>
      )}
    </div>
  );
}

export default SecNavbar;
