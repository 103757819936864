import SecNavbar from "./SecondNavbar"
import Starting from "./StartingSearch"
// import Starting2 from "./StartingSearch2";



function MainSearchingPage(){
    return(
        <div className="NewPage">
             <SecNavbar />
            <Starting />
            {/* <Starting2 /> */}
            {/* <ResultContainer /> */}
        </div>
    )
}

export default MainSearchingPage;