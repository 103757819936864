import React from "react";
// Import Custom Css File
import "./Recomendation.css";
// Import Images
import building from "../../images/building.jpg";
// Import Fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';



const recommendations = [
  { id: 1, name: "საქართველოს ბანკი", logo: building },
  { id: 2, name: "საქართველოს ბანკი", logo: building },
  { id: 3, name: "საქართველოს ბანკი", logo: building },
  { id: 4, name: "საქართველოს ბანკი", logo: building },
  { id: 5, name: "საქართველოს ბანკი", logo: building },
  { id: 6, name: "საქართველოს ბანკი", logo: building },
  { id: 7, name: "საქართველოს ბანკი", logo: building },
];

function Recomendation() {
  return (
    <div className="Recomendation-Container">
      <div className="Recomendation-Context">

        <div className="RecomendationMain-Title">
          <h4 className="RecomendationTitle">რეკომენდაციები <span className="InfoBtn5"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="#9298A6" className="anri"><path d="M12 22C6.47715 22 2 17.5228 2 12 2 6.47715 6.47715 2 12 2 17.5228 2 22 6.47715 22 12 22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12 20 7.58172 16.4183 4 12 4 7.58172 4 4 7.58172 4 12 4 16.4183 7.58172 20 12 20ZM13 10.5V15H14V17H10V15H11V12.5H10V10.5H13ZM13.5 8C13.5 8.82843 12.8284 9.5 12 9.5 11.1716 9.5 10.5 8.82843 10.5 8 10.5 7.17157 11.1716 6.5 12 6.5 12.8284 6.5 13.5 7.17157 13.5 8Z"></path></svg></span></h4>
        </div>
        <div className="Recomendation-MainRow">
          <img src={building} alt="This is BOG LOGO " className="Recomendation-BOG2"></img>
          <h4 className="Recomendation-Bog">საქართველოს ბანკი</h4>
          <div className="together">
            <h4 className="CompereItem"><span className="ArrowIcons"><FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ transform: "translateX(-10px)" }} /></span>შედარება</h4>
            <h4 className="SaveItem"><span className="SaveIcon"><FontAwesomeIcon icon={faPlus} style={{ color: "#656AF9", transform: "translateX(-10px)", marginTop: "-2.4rem" }} /></span>შენახვა</h4>
          </div>
          <hr className="recommendation-border-color2"></hr>
        </div>
        {/*  */}

        {recommendations.map((recommendation) => (
          <div key={recommendation.id} className="Recomendation-OrdinaryRow">
            <img
              src={recommendation.logo}
              alt={`This is ${recommendation.name} LOGO`}
              className="Recomendation-BOG"
            />
            <h4 className="Recomendation-Bog">{recommendation.name}</h4>
            <div className="together2">
              <h4 className="CompereItemForBank">
                <span className="ArrowIcons">
                  <FontAwesomeIcon
                    icon={faArrowRightArrowLeft}
                    style={{ transform: "translateX(-10px)" }}
                  />
                </span>
                შედარება
              </h4>
              <h4 className="SaveItemForBank">
                <span className="SaveIcon">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                      color: "#656AF9",
                      transform: "translateX(-10px)",
                      marginTop: "-2.4rem",
                    }}
                  />
                </span>
                შენახვა
              </h4>
            </div>
            <hr className="recommendation-border-color" />
          </div>
        ))}



      </div>
    </div>

  )
}


export default Recomendation;


