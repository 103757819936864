import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import "./StartingSearch.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// Import Company Images
import TBC from "../../images/TBC.png";
import AVERSI from "../../images/aversi.jpg";
import NIKORA from "../../images/nikora.jpeg";
import TEGETA from "../../images/tegeta.jpeg";
import News from "../News/News";
import SearchingResult from "../StartingResult/SearchResultt";

interface SearchResult {
  id: number;
  name: string;
  id_code: number;
  Organization: string;
  LatestValues: {
    [key: string]: [number, number];
  };
  PercentageDifferences: {
    [key: string]: number;
  };
  PreviousValues: {
    [key: string]: [number, number];
  };
}

function Starting() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [lineStyle, setLineStyle] = useState({});
  const itemRefs = useRef(new Array(4).fill(null));

  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // Store the search term
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL
  const FrontendUrl = process.env.REACT_APP_FRONTEND_URL

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const activeItem = itemRefs.current[activeIndex];
    if (activeItem) {
      setLineStyle({
        width: `${activeItem.offsetWidth}px`,
        left: `${activeItem.offsetLeft}px`,
        top: `32px`,
        height: `2.1px`
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchTerm.length >= 3) {
        try {
          const response = await fetch(`${BackendUrl}/get_detailed_search_query?query=${searchTerm}`);
          const data = await response.json();
          setSearchResults(data);
        } catch (error) {
          console.error('Error fetching search data:', error);
        }
      } else {
        setSearchResults([]);
      }
    }, 1000); // Wait for 2 seconds

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts or input changes
  }, [searchTerm, BackendUrl]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    setSearchTerm(value); // Update the search term state
  };

  const companies = [
    { name: 'თიბისი', imgSrc: TBC, imgAlt: 'TBC', imgClass: 'TBC-IMAGES', textClass: 'TBC', url: `${FrontendUrl}/company/204854595` },
    { name: 'ავერსი', imgSrc: AVERSI, imgAlt: 'Aversi', imgClass: 'LIBERTY-IMAGES', textClass: 'LIBERTY', url: `${FrontendUrl}/company/208216107` },
    { name: 'ნიკორა', imgSrc: NIKORA, imgAlt: 'Nikora', imgClass: 'ORIS-IMAGES', textClass: 'ORIS', url: `${FrontendUrl}/company/200050675` },
    { name: 'თეგეტა', imgSrc: TEGETA, imgAlt: 'Tegeta', imgClass: 'TERA-IMAGES', textClass: 'TERA', url: `${FrontendUrl}/company/202177205` },
  ];

  const iteminio = [
    { label: 'კომპანია', className: 'Company', clickable: true },
    { label: 'სიახლეები', className: 'Company', clickable: true },
    { label: 'ინდუსტრია', className: 'Company', clickable: false },
    { label: 'ხალხი', className: 'People', clickable: false },
  ];
  return (
    <div>
      <div className="Main-ContainerMainSearching-second">
        <div className="AllItems-second">
          <div className="startingTitle-second">
            <h2 className="FinancialInformation-second">დაიწყე ძებნა</h2>
          </div>
          <div className="SearchBar-second">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon-second" />
            <input ref={inputRef} type="search" value={inputValue} onChange={handleChange} className="search-second no-focus-outline-second" placeholder="კომპანია/საიდენტიფიკაციო კოდი" style={{ color: "rgba(146, 152, 166, 1)", fontSize: "1rem", fontWeight: "500" }} />
          </div>
          <div className="CompanyContainer-second">
            {companies.map((company, index) => (
              <div className="Company1-second pointer-cursor-second" key={index} onClick={() => window.location.href = company.url}>
                <span className={company.imgClass + "-second"}>
                  <img src={company.imgSrc} alt={company.imgAlt} className={company.imgClass + "-second"} style={{ marginTop: "0px" }} />
                </span>
                <p className={company.textClass + "-second"} style={{ transform: "translateX(5px)" }}>
                  {company.name}
                </p>
              </div>
            ))}
          </div>
          <div className="ItemList-second">
            {iteminio.map((item, index) => (
              <div
                key={index}
                ref={el => itemRefs.current[index] = el}
                onClick={() => item.clickable && setActiveIndex(index)}
                className={`Title1-second ${!item.clickable ? 'non-clickable-second' : ''}`}
                style={{ color: activeIndex === index ? '#656AF9' : 'rgba(60, 60, 67, 0.6)' }}
              >
                <h5 className={item.className + "-second"}>{item.label}</h5>
                {!item.clickable && <span style={{ borderRadius: '15px', marginLeft: '0.51rem', width: '70px', height: "20px", textAlign: 'center', backgroundColor: 'rgb(255, 184, 0, 0.1)', color: 'rgb(255, 184, 0)' }} className="Soon-second">Soon</span>}
              </div>
            ))}
            <hr className="Active-second" style={lineStyle} />
          </div>
        </div>
      </div>
      {activeIndex === 0 && <SearchingResult searchResults={searchResults} />}
      {activeIndex === 1 && <News />}
    </div>

  )
}

export default Starting;
