import React, { useState, useEffect } from 'react';
import './News.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Define types for the data structure
interface NewsImage {
    boxed: string;
    boxed_thumbnail: string;
    wide: string;
    wide_thumbnail: string;
}

interface NewsItem {
    id: number;
    images: NewsImage;
    publish_date: string;
    title: string;
    url: string;
}

interface NewSProps {
    setActiveMainIndex: (index: number) => void;
}

// Utility function to truncate the title
const truncateTitle = (title: string, maxLength: number): string => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
};

const NewS: React.FC<NewSProps> = ({ setActiveMainIndex }) => {
    const [newsData, setNewsData] = useState<NewsItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const BackendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Simulate a delay to show the loader
        setTimeout(() => {
            fetch(`${BackendUrl}/bm_ge/get-all-news`)
                .then(response => response.json())
                .then(data => {
                    setNewsData(data.data.slice(0, 4)); // Limit to 5 items
                    setLoading(false);
                })
                .catch(error => console.error('Error fetching news:', error));
        }, 1000); // Simulate a 1-second delay
    }, []);

    const handleSeeMoreClick = () => {
        setActiveMainIndex(1); // Set the main index to 1 for HeaderNews
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    };

    const NextPage = (url: string) => {
        window.open(url);
    };

    return (
        <div className="MainContainerNews">
            {/* Company Title */}
            <div className="CompanyNews-Formainpage">
                <h3 className='Title'>კომპანიის <span className='PartTitle'>სიახლეები</span></h3>
            </div>

            <div className="SeeMore-News">
                <h5 className="See-News" onClick={handleSeeMoreClick}>მეტის ნახვა</h5>
            </div>

            <div className='AllContent-MainPageNews'>
                {loading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} className="MainNews skeleton">
                            <div className="skeleton-img"></div>
                            <h5 className="skeleton-title"></h5>
                            <h5 className="skeleton-media"></h5>
                            <h5 className="skeleton-date"></h5>
                            <h5 className="skeleton-full"></h5>
                        </div>
                    ))
                ) : (
                    newsData.map((newsItem) => (
                        <div
                            key={newsItem.id}
                            className="MainNews"
                            onClick={() => NextPage(newsItem.url)}
                        >
                            <img
                                src={newsItem.images.boxed_thumbnail}
                                alt={newsItem.title}
                                className='imigi'
                            />
                            <h5 className='NewsTitle'>
                                {truncateTitle(newsItem.title, 50)} {/* Truncate title to 50 characters */}
                            </h5>
                            <h5 className="Media-Navbarnews">Business Media</h5>
                            <h5 className='TimeAndDate'>{new Date(newsItem.publish_date).toLocaleDateString('ka-GE', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })}</h5>
                            <h5 className='Full'>ვრცლად <span className='fonticon'><FontAwesomeIcon icon={faChevronRight} style={{ transform: "translateY(1.5px)" }} /></span></h5>
                        </div>
                    ))
                )}
            </div>

            <div className="advertising">
                <h5 className='bmge'>Powered by bm.ge</h5>
            </div>
        </div>
    );
};

export default NewS;
