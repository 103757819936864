import React, { useState, useEffect } from 'react';
import './Table.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface DataItem {
  LineItemGEO: string;
  value_gel: string;
  value_gel_previous?: string;
}

function Table() {
  const { idCode } = useParams<string>();
  const [dataFromEndpointsCombined, setDataFromEndpointsCombined] = useState<DataItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('2022');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      // Construct a session storage key based on idCode and selectedOption
      const storageKey = `${idCode}-${selectedOption}`;

      // Check if data is already stored in sessionStorage
      const storedData = sessionStorage.getItem(storageKey);

      if (storedData) {
        // Use the stored data if available
        setDataFromEndpointsCombined(JSON.parse(storedData));
      } else {
        // Fetch the data from the API if not in sessionStorage
        setIsLoading(true);
        try {
          const [response1, response2] = await Promise.all([
            axios.get(`${BackendUrl}/fuladi_saxsrebis_modzraoba/${idCode}?year=${selectedOption}`),
            axios.get(`${BackendUrl}/fuladi_saxsrebis_modzraoba/previous/${idCode}?year=${selectedOption}`)
          ]);

          const data1: DataItem[] = response1.data.data;
          const data2 = response2.data.data.reduce((acc: { [key: string]: DataItem }, item: DataItem) => {
            acc[item.LineItemGEO] = item;
            return acc;
          }, {});

          const combinedData: DataItem[] = data1.map(item => {
            const matchedItem = data2[item.LineItemGEO] || { value_gel: '0' };
            return {
              ...item,
              value_gel_previous: matchedItem.value_gel,
            };
          });

          // Save combined data to sessionStorage
          sessionStorage.setItem(storageKey, JSON.stringify(combinedData));

          // Update state
          setDataFromEndpointsCombined(combinedData);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [idCode, selectedOption, BackendUrl]);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  if (isLoading) {
    return <div className='loader'></div>;
  }

  return (
    <div className='table-container'>
      <select className='tableSelect' value={selectedOption} onChange={handleDropdownChange}>
        <option value="" disabled hidden>აირჩიეთ წელი</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
      </select>
      <div className='tableWrapper'>
        <div className='powered-by'>
          Powered by reportal.ge
        </div>
        <table className='tableClass'>
          <thead>
            <tr>
              <th>განახლდა 15 მარ, 2024</th>
              <th>საანგარიშგებო პერიოდი</th>
              <th>წინა საანგარიშგებო პერიოდი</th>
              <th>თანხობრივი ცვლილება</th>
              <th>პროცენტული ცვლილება</th>
            </tr>
          </thead>
          <tbody>
            {dataFromEndpointsCombined.map((item, index) => {
              const value1 = parseFloat(item.value_gel);
              const value2 = parseFloat(item.value_gel_previous || '0');

              let difference = value1 - value2;
              const rawPercentage = ((value1 - value2) / Math.abs(value2)) * 100;
              let percentage = Number.isFinite(rawPercentage) ? `${rawPercentage.toFixed(2)}%` : "";

              // Determine the class name based on the LineItemGEO
              let className = '';
              if (item.LineItemGEO === 'ფულადი სახსრების შემოსვლა (გასვლა)') {
                className = 'highlighted-row';
              } else if (item.LineItemGEO === 'ფულადი სახსრები და მათი ეკვივალენტები საანგარიშგებო პერიოდის ბოლოს') {
                className = 'highlighted-row';
              }

              return (
                <tr key={index} className={className}>
                  <td>{item.LineItemGEO}</td>
                  <td>{value1.toLocaleString()}</td>
                  <td>{value2.toLocaleString()}</td>
                  <td>{difference.toLocaleString()}</td>
                  <td>{percentage}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
