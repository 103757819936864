import React, { useState, useEffect } from 'react';
import './Table.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface DataItem {
  LineItemGEO: string;
  value_gel: string;
  value_gel_previous?: string;
}

function Table() {
  const { idCode } = useParams<{ idCode: string }>();
  const [dataFromEndpointsCombined, setDataFromEndpointsCombined] = useState<DataItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('2022');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (selectedOption) {
      const cacheKey = `financialData_${idCode}_${selectedOption}`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        // Use cached data
        setDataFromEndpointsCombined(JSON.parse(cachedData));
      } else {
        // Fetch data from the API if not cached
        setIsLoading(true);
        Promise.all([
          axios.get(`${BackendUrl}/finansuri_mdgomareoba/${idCode}?year=${selectedOption}`),
          axios.get(`${BackendUrl}/finansuri_mdgomareoba/previous/${idCode}?year=${selectedOption}`)
        ])
          .then(([response1, response2]) => {
            const data1: DataItem[] = response1.data.data;
            const data2: { [key: string]: DataItem } = response2.data.data.reduce(
              (acc: { [key: string]: DataItem }, item: DataItem) => {
                acc[item.LineItemGEO] = item;
                return acc;
              },
              {}
            );
            const combinedData: DataItem[] = data1.map(item => {
              const matchedItem = data2[item.LineItemGEO] || { value_gel: '0' }; // Default to '0' if not found
              return {
                ...item,
                value_gel_previous: matchedItem.value_gel,
              };
            });

            // Sort the combined data based on a custom order
            const customOrder: string[] = [
              "ფულადი სახსრები და მათი ეკვივალენტები",
              "სავაჭრო და სხვა მოთხოვნები",
              "სხვა პირებზე ავანსებად და სესხებად გაცემული ფულადი სახსრები",
              "მარაგები",
              "მზა პროდუქცია",
              "სხვა",
              "საინვესტიციო ქონება",
              "სხვა მოკლევადიანი აქტივები",
              "ბიოლოგიური აქტივები",
              "თვითღირებულების მოდელით",
              "გადაფასების მოდელით",
              "გუდვილი",
              "სხვა არამატერიალური აქტივები",
              "ძირითადი საშუალებები",
              "ინვესტიციები შვილობილ კომპანიებში",
              "ინვესტიციები სხვა საწარმოთა ნაწილობრივ ინსტრუმენტებში",
              "სხვა გრძელვადიანი აქტივები",
              "სულ აქტივები",
              "ვალდებულებები",
              "საიჯარო ვალდებულება",
              "სავაჭრო ვალდებულებები",
              "ანარიცხები",
              "ვალდებულებები თანამშრომელთა მიმართ",
              "საგადასახადო ვალდებულებები",
              "გამოუმუშავებელი შემოსავალი",
              "მოკლევადიანი ნასესხები სახსრები",
              "გრძელვადიანი ნასესხები სახსრები",
              "მიღებული გრანტები",
              "სხვა ვალდებულებები",
              "სულ ვალდებულებები",
              "საკუთარი კაპიტალი",
              "სააქციო კაპიტალი",
              "საემისიო შემოსავალი",
              "გამოსყიდული საკუთარი აქციები",
              "გაუნაწილებელი მოგება/(დაგროვილი ზარალი)",
              "გადაფასების რეზერვი",
              "საკუთარი კაპიტალის სხვა კომპონენტები",
              "სულ საკუთარი კაპიტალი",
              "სულ საკუთარი კაპიტალი და ვალდებულებები",
              "სხვა მოკლევადიანი აქტივები",
              "სხვა მოკლევადიანი ვალდებულებები"
            ];

            combinedData.sort((a, b) => {
              let indexA = customOrder.indexOf(a.LineItemGEO);
              let indexB = customOrder.indexOf(b.LineItemGEO);
              if (indexA === -1) indexA = customOrder.length + 1;
              if (indexB === -1) indexB = customOrder.length + 1;
              return indexA - indexB;
            });

            // Cache the combined data
            sessionStorage.setItem(cacheKey, JSON.stringify(combinedData));

            setDataFromEndpointsCombined(combinedData);
            setIsLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          });
      }
    }
  }, [idCode, selectedOption, BackendUrl]);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  // Split the data into multiple tables based on sections
  const splitIndex = dataFromEndpointsCombined.findIndex(item => item.LineItemGEO === "სულ აქტივები") + 1;
  const splitIndex2 = dataFromEndpointsCombined.findIndex(item => item.LineItemGEO === "სულ ვალდებულებები") + 1;

  const firstHalfData = dataFromEndpointsCombined.slice(0, splitIndex);
  const secondHalfData = dataFromEndpointsCombined.slice(splitIndex, splitIndex2);
  const thirdPartData = dataFromEndpointsCombined.slice(splitIndex2);

  const renderTable = (data: DataItem[], isFirstTable: boolean, isSecondTable: boolean, isThirdTable: boolean) => (
    <div className='tableWrapper'>
      <div className='powered-by'>
        Powered by reportal.ge
      </div>
      <table className='tableClass'>
        <thead>
          <tr>
            <th>განახლდა 15 მარ, 2024</th>
            <th>საანგარიშგებო პერიოდის თარიღი</th>
            <th>წინა საანგარიშგებო პერიოდის თარიღი</th>
            <th>თანხობრივი ცვლილება</th>
            <th>პროცენტული ცვლილება</th>
          </tr>
        </thead>
        <tbody>
          {isFirstTable && (
            <tr className='highlighted-row'>
              <td>აქტივები</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
          {isSecondTable && (
            <tr className='highlighted-obligation-row'>
              <td>ვალდებულებები</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
          {isThirdTable && (
            <tr className='highlighted-capital-row'>
              <td>საკუთარი კაპიტალი</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
          {data.map((item, index) => {
            const value1 = parseFloat(item.value_gel);
            const value2 = parseFloat(item.value_gel_previous || '0');
            const difference = value1 - value2;
            const rawPercentage = ((value1 - value2) / Math.abs(value2)) * 100;
            const percentage = Number.isFinite(rawPercentage) ? `${rawPercentage.toFixed(2)}%` : "";

            const isTotalAssets = item.LineItemGEO === "სულ აქტივები";
            const isTotalObligation = item.LineItemGEO === "სულ ვალდებულებები";
            const isTotalCapital = item.LineItemGEO === "სულ საკუთარი კაპიტალი";
            const isTotalCapitalAndObligation = item.LineItemGEO === "სულ საკუთარი კაპიტალი და ვალდებულებები";

            return (
              <tr
                key={index}
                className={`${isTotalAssets ? 'highlighted-row' : ''} ${isTotalObligation ? 'highlighted-row' : ''} ${isTotalCapital ? 'highlighted-row' : ''} ${isTotalCapitalAndObligation ? 'highlighted-row' : ''}`}
              >
                <td>{item.LineItemGEO}</td>
                <td>{value1.toLocaleString()}</td>
                <td>{value2.toLocaleString()}</td>
                <td>{difference.toLocaleString()}</td>
                <td>{percentage}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  if (isLoading) {
    return <div className='loader'></div>;
  }

  return (
    <div className='table-container'>
      <select className='tableSelect' value={selectedOption} onChange={handleDropdownChange}>
        <option value="" disabled hidden>აირჩიეთ წელი</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
      </select>
      {renderTable(firstHalfData, true, false, false)}
      {renderTable(secondHalfData, false, true, false)}
      {renderTable(thirdPartData, false, false, true)}
    </div>
  );
}

export default Table;
