import React, { useState, useEffect, useRef, FunctionComponent } from "react";
import "./StartingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import MultipleSelect from "../StartingPageCompanyFilters/StartingPageCompanyFilters";
// Make sure your image imports work with TypeScript.
// You might need to declare them if TypeScript complains.
import TBC from "../../images/TBC.png";
import AVERSI from "../../images/aversi.jpg";
import NIKORA from "../../images/nikora.jpeg";
import TEGETA from "../../images/tegeta.jpeg";
// import News from "../News/News";
import MainPageNews from "../MainPageNews/MainPageNews";
import LastView from "../LastView/LastView";
import Recomendation from "../Recomendation/Recomendation";
import NavbarMainPage from "../NavbarMainPage/NavbarMainPage";
import MainSearchingPage from "../SearchingPageCompany/SearchingPageCompany";
import Reminder from "../Authorization-Reminder/Reminder";

interface LineStyle {
  width: string;
  left: string;
}

interface Suggestion {
  id: number;
  name: string;
}

const Startingster: FunctionComponent = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [lineStyle, setLineStyle] = useState<LineStyle>({ width: "0", left: "0" });
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [tokenAvailable, setTokenAvailable] = useState<boolean>(false);
  const FrontendUrl = process.env.REACT_APP_FRONTEND_URL

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const activeItem = itemRefs.current[activeIndex];
    if (activeItem) {
      setLineStyle({
        width: `${activeItem.offsetWidth}px`,
        left: `${activeItem.offsetLeft}px`,
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenAvailable(!!token);
  }, []);

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  const companies = [
    { name: 'თიბისი', imgSrc: TBC, imgAlt: 'TBC', imgClass: 'TBC-IMAGES', textClass: 'TBC', url: `${FrontendUrl}/company/204854595` },
    { name: 'ავერსი', imgSrc: AVERSI, imgAlt: 'Aversi', imgClass: 'LIBERTY-IMAGES', textClass: 'LIBERTY', url: `${FrontendUrl}/company/208216107` },
    { name: 'ნიკორა', imgSrc: NIKORA, imgAlt: 'Nikora', imgClass: 'ORIS-IMAGES', textClass: 'ORIS', url: `${FrontendUrl}/company/200050675` },
    { name: 'თეგეტა', imgSrc: TEGETA, imgAlt: 'Tegeta', imgClass: 'TERA-IMAGES', textClass: 'TERA', url: `${FrontendUrl}/company/202177205` },
  ];


  return (
    <div>
      {!showNewComponent && <NavbarMainPage />}
      <div className="background">
        {showNewComponent ? (
          <MainSearchingPage />
        ) : (
          <>
            <div className="Main-Container-EasySearch">
              <div className="AllItems-SearchItems">
                <div className="Title">
                  <h2 className="FinancialInformation">
                    მარტივად მოიძიე 10 000-ზე მეტი კომპანიის ფინანსური ინფორმაცია
                  </h2>
                </div>
                <div className="SearchBar">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                  <input
                    type="search"
                    className="search"
                    placeholder="კომპანია/საიდენტიფიკაციო კოდი"
                    onClick={() => setShowNewComponent(true)}
                  />
                </div>
                <div className="CompanyContainer-StartingPage">
                  {companies.map((company, index) => (
                    <div className="Company1 pointer-cursor" key={index} onClick={() => window.location.href = company.url}>
                      <span className={company.imgClass} style={{ transform: "translateX(-3px) translateY(0px)" }}>
                        <img src={company.imgSrc} alt={company.imgAlt} className={company.imgClass} style={{ marginTop: "6px" }} />
                      </span>
                      <p className={company.textClass} style={{ transform: "translateX(3px)" }}>
                        {company.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mainPageWrapper">
              {tokenAvailable ? (
                <>
                  <LastView />
                  <Recomendation />
                </>
              ) : (
                <Reminder />
              )}
            </div>
            <MainPageNews />
          </>
        )}
      </div>
    </div>
  );
};

export default Startingster;
