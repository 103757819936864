import React from 'react';
import ReactDOM from 'react-dom';
import './CompareOverlay.css';
import Compare from './Compare';

interface CompareOverlayProps {
  onClose: () => void;
}

const CompareOverlay: React.FC<CompareOverlayProps> = ({ onClose }) => {
  return ReactDOM.createPortal(
    <div className="overlay" onClick={onClose}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <Compare/>
      </div>
    </div>,
    document.body
  );
};

export default CompareOverlay;
