import React, { useState } from "react";
import axios from 'axios';
import "./Authorization.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import GeorgiaFlag from "../../images/GeorgiaFlag.png";

function Authorization() {
    // CheckBox Truth Or false 
    const AuthUrl = process.env.REACT_APP_AUTHAPI_URL
    const [isChecked, setIsChecked] = useState(false);
    const toggleCheck = () => {
        setIsChecked(!isChecked);
    };

    // SmsCode Functionality (Only write digit number)
    const [smsCode, setSmsCode] = useState('');
    const handleSmsCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === '' || /^[0-9]{1,6}$/.test(value)) {
            setSmsCode(value);
        }
        else {
            console.log("TRY AGAIN")
        }
    };

    // PhoneNumber Input Functionality
    const [phoneNumber, setPhoneNumber] = useState('');
    const formatPhoneNumber = (value: string) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '').substring(0, 9);
        return phoneNumber;
    };
    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const formattedPhoneNumber = formatPhoneNumber(value);
        setPhoneNumber(formattedPhoneNumber);
    };

    // Password Input Functionality
    const [password, setPassword] = useState('');
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    // State for error message
    const [errorMessage, setErrorMessage] = useState('');

    // Handle create account click
    const handleCreateAccountClick = () => {
        console.log("Create account clicked");
    };

    // Handle login
    const handleLogin = async () => {
        try {
            const response = await axios.post(`${AuthUrl}/auth/authorize`, {
                mobile: phoneNumber,
                password: password
            });

            if (response.data.success) {
                const token = response.data.token;
                console.log('Token:', token);
                localStorage.setItem('token', token);
                setErrorMessage('');  // Clear error message on successful login
                window.location.reload();  // Refresh the page
            } else {
                if (response.data.message === "Invalid credentials") {
                    setErrorMessage("მონაცემები არასწორია.");
                } else {
                    setErrorMessage("მომხმარებელი არ მოიძებნა.");
                }
            }
        } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage("მონაცემები არასწორია.");  // Set a generic error message
        }
    };

    return (
        <div className="Main-Container">
            <div className="Navbar-Content">
                {/* ავტორიზაციის სათაური */}
                <div className="AuthorizationTitle">
                    <h4 className="TitleAuthorization">ავტორიზაცია</h4>
                </div>

                {/* Error message */}
                {errorMessage && <div className="ErrorMessage" style={{ color: 'red' }}>{errorMessage}</div>}

                {/* შეიყვანეთ ტელეფონის ნომერი */}
                <div className="PhoneNumber">
                    <img src={GeorgiaFlag} alt="Georgian Flag" className="Flag" />
                    <h4 className="StartingNum">+995</h4>
                    <input
                        type="text"
                        className="InputNum"
                        placeholder="ტელეფონის ნომერი"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                </div>

                {/* SMS კოდი */}
                <div className="SmsCode">
                    <input
                        type="password"
                        className="Sms"
                        placeholder="პაროლი"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>

                {/* დამახსოვრება */}
                <div className="Remember-Container" onClick={toggleCheck}>
                    <FontAwesomeIcon icon={isChecked ? faSquareCheck : faSquare} className="Check" />
                    <h5 className="Remember">დამახსოვრება</h5>
                </div>

                {/* ავტორიზაციის მთავარი ღილაკი */}
                <div className="AuthorizationButton">
                    <button type="button" className="MainAuthorization" onClick={handleLogin}>ავტორიზაცია</button>
                </div>

                {/* Create Account link */}
            </div>
        </div>
    );
}

export default Authorization;
