import SimilarCompanies from "../similarCompanies/SearchRes";
import './Wrapper.css'

// Iport Images
import { useEffect, useState } from "react";
import LastView from "../LastView/LastView";
import Recomendation from "../Recomendation/Recomendation";
import NavbarSimilarCompanies from "../NavbarSimilar/NavbarSimilarCompanies";
import Reminder from "../Authorization-Reminder/Reminder";
function SimilarCompaniesWrapper() {
    const [tokenAvailable, setTokenAvailable] = useState<boolean>(false);

    useEffect(() => {
      const token = localStorage.getItem("token");
      setTokenAvailable(!!token);
    }, []);
  
    return(
        <div>
        <NavbarSimilarCompanies/>
        <div className="Global-wrapper">
        {tokenAvailable ? (
                <>
                  <LastView />
                  <Recomendation />
                </>
              ) : (
                <Reminder />
              )}
        </div>
        </div>
    )

}

export default SimilarCompaniesWrapper;