import React, { useState, useEffect } from 'react';
import './MyComponent.css';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import DeleteIcon from '@mui/icons-material/Delete';


interface CustomJwtPayload extends JwtPayload {
  user_id: string;
}

interface SavedCompanyItem {
  idCode: string;
  OrgNameInReport: string;
}

interface MyComponentProps {
  setShowProfilePage: (show: boolean) => void;
}

const MyComponent: React.FC<MyComponentProps> = ({ setShowProfilePage }) => {
  const [selectedTab, setSelectedTab] = useState('favoriteCompanies');
  const [savedCompanies, setSavedCompanies] = useState<SavedCompanyItem[]>([]);
  const AuthUrl = process.env.REACT_APP_AUTHAPI_URL;
  console.log(AuthUrl);

  useEffect(() => {
    const fetchSavedCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode<CustomJwtPayload>(token);
          const userId = decodedToken.user_id;

          const response = await fetch(`${AuthUrl}/get_private_page_saved_companies?userId=${userId}`);
          const data = await response.json();
          if (response.ok) {
            console.log('Saved companies data fetched:', data.saved_companies);
            setSavedCompanies(data.saved_companies);
          } else {
            console.error(data.error);
          }
        } else {
          console.error('No JWT token found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching saved companies info:', error);
      }
    };

    if (selectedTab === 'favoriteCompanies') {
      fetchSavedCompanies();
    }
  }, [selectedTab]);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Delete the token from localStorage
    window.location.reload(); // Refresh the page
  };

  const deleteCompany = async (idCode: string) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const userId = jwtDecode<CustomJwtPayload>(token).user_id;

        const response = await fetch(`${AuthUrl}/delete_private_page_saved_company`, {

          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ userId, idCode })
        });

        const data = await response.json();
        if (response.ok) {
          console.log('Company deleted successfully:', data.message);
          setSavedCompanies(prevCompanies => prevCompanies.filter(company => company.idCode !== idCode));
        } else {
          console.error('Error from server:', data.error);
        }
      } else {
        console.error('No JWT token found in localStorage');
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  return (
    <div className="my-component-container">
      <div className="Shutdown">
        <button className="my-component-close-button" onClick={() => setShowProfilePage(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
            <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
          </svg>
        </button>
      </div>

      <div className="my-component-sidebar">
        <div className="my-component-header">
          <h2>პირადი გვერდი</h2>
        </div>

        <div className="SavingCompanyIndustry">
          <button
            className={selectedTab === 'favoriteCompanies' ? 'active' : ''}
            onClick={() => handleTabClick('favoriteCompanies')}
            style={{ fontSize: '16px' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor" className='HeartIcon'>
              <path d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z"></path>
            </svg>
            შენახული კომპანიები
          </button>

          <div className="ChangingNum">
            <button
              className={selectedTab === 'contactNumbers' ? 'active' : ''}
              onClick={() => handleTabClick('contactNumbers')}
              style={{ fontSize: '15px' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className='TelephoneIcon'>
                <path d="M7 4V20H17V4H7ZM6 2H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2ZM12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17Z"></path>
              </svg>
              ნომრის ცვლილება
            </button>
          </div>
        </div>

        <div className="Leave-Page">
          <button type='button' onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className='left-arrow-icon'>
              <path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path>
            </svg>
            გასვლა
          </button>
        </div>
      </div>

      <div className='SavingCompany-2'>
        <div className='Savingcompany'>
          <h2>შენახული კომპანიები</h2>
        </div>
        {selectedTab === 'favoriteCompanies' && (
          <h6></h6>
        )}

        {selectedTab === 'favoriteCompanies' && (
          <div className="my-component-favorite-companies">
            {savedCompanies.map((company, index) => (
              <div key={index} className="my-component-company-card">
                <h6 className='seemorinio'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor" className='ArrowIcon'>
                    <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
                  </svg> ნახვა
                </h6>
                <div>
                  <p className='CompanyTitle2'>{company.OrgNameInReport}</p>
                </div>
                <button className="my-component-delete-button" onClick={() => deleteCompany(company.idCode)}>
                  <DeleteIcon style={{ fontSize: 20 }} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>




      <div className="my-component-content">
        {selectedTab === 'contactNumbers' && (
          <div className="my-component-contact-numbers">
            <div className="my-component-number-group">
              <h2 className='ChangingNumberText'>ნომრის ცვლილება</h2>
              <p className='existing-number'>მიუთითეთ არსებული ნომერი</p>
              <input type="text" placeholder='ტელეფონის ნომერი' />
              <input type="text" className='SmsCode-Input' placeholder='SMS კოდი' />
              <button className='SendMessage'>კოდის მიღება</button>
            </div>

            <div className="my-component-number-group-second">
              <p className='existing-NewNumber'>მიუთითეთ ახალი ნომერი</p>
              <input type='input' className='Telephone-Number-Sec' placeholder='ტელეფონის ნომერი' />
              <input type='input' className='SmsCode-Input2' placeholder='SMS კოდი' />
              <button className='Code-Receive'>კოდის მიღება</button>
            </div>
            <button className="my-component-update-button">განახლება</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyComponent;
