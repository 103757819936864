import React, { useState, useEffect } from 'react';
import './Table.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface DataItem {
  LineItemGEO: string;
  value_gel: string;
  value_gel_previous: string;
}

function Table() {
  const { idCode } = useParams<string>();
  const [dataFromEndpointsCombined, setDataFromEndpointsCombined] = useState<DataItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('2022');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (selectedOption) {
      setIsLoading(true);

      // Fetch data from endpoints
      Promise.all([
        axios.get(`${BackendUrl}/saqmianobis_shedegebi/${idCode}?year=${selectedOption}`),
        axios.get(`${BackendUrl}/saqmianobis_shedegebi/previous/${idCode}?year=${selectedOption}`)
      ]).then(([response1, response2]) => {
        const data1: DataItem[] = response1.data.data;
        const data2 = response2.data.data.reduce((acc: Record<string, DataItem>, item: DataItem) => {
          acc[item.LineItemGEO] = item;
          return acc;
        }, {});

        const combinedData = data1.map(item => {
          const matchedItem = data2[item.LineItemGEO] || { value_gel: '0', value_gel_previous: '0' };
          return {
            ...item,
            value_gel_previous: matchedItem.value_gel,
          };
        });

        // Custom order for sorting the table rows
        const customOrder = [
          "ნეტო ამონაგები",
          "საქონლის გაყიდვიდან",
          "მომსახურების გაწევიდან",
          "სამშენებლო ხელშეკრულებებიდან",
          "სხვა",
          "გაყიდვების თვითღირებულება",
          "საერთო მოგება",
          "სხვა შემოსავალი",
          "ადმინისტრაციისა და გაყიდვების ხელფასების ხარჯი",
          "ცვეთა / ამორტიზაციის ხარჯი",
          "გაუფასურების ხარჯი:",
          "ფინანსური აქტივები",
          "მარაგები",
          "სხვა არაფინანსური აქტივები",
          "საპროცენტო შემოსავალი",
          "საპროცენტო ხარჯი",
          "დივიდენდად გაცემული არაფულადი აქტივის საბაზრო ღირებულების კორექტირება",
          "ცვლილება ბიოლოგიური აქტივის გასაყიდ ფასში",
          "სხვა ხარჯები",
          "მოგება / ზარალი, მოგების გადასახადის ხარჯამდე",
          "მოგების გადასახადის ხარჯი",
          "საანგარიშგებო პერიოდის მოგება / ზარალი",
          "საწყისი გაუნაწილებელი მოგება: გადაანგარიშებული",
          "გაუნაწილებელი მოგების საწყისი ნაშთი (წინა წლის საბოლოო ნაშთი)",
          "გასული პერიოდის შეცდომის გასწორების გავლენა",
          "გასული პერიოდის სააღრიცხვო პოლიტიკის ცვლილების გავლენა",
          "განაწილება მესაკუთრეებზე (მათ შორის დივიდენდი)",
          "გაუნაწილებელი მოგების საბოლოო ნაშთი"
        ];

        // Sorting data based on customOrder array
        combinedData.sort((a, b) => {
          let indexA = customOrder.indexOf(a.LineItemGEO);
          let indexB = customOrder.indexOf(b.LineItemGEO);

          if (indexA === -1) indexA = customOrder.length + 1;
          if (indexB === -1) indexB = customOrder.length + 1;
          return indexA - indexB;
        });

        setDataFromEndpointsCombined(combinedData);
        setIsLoading(false);
      }).catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
    }
  }, [idCode, selectedOption]);

  // Handle dropdown change
  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  // Find index for splitting data into parts
  const splitIndex = dataFromEndpointsCombined.findIndex(item => item.LineItemGEO === "საანგარიშგებო პერიოდის მოგება / ზარალი") + 1;

  // Split the data into 2 arrays
  const firstHalfData = dataFromEndpointsCombined.slice(0, splitIndex);
  const secondHalfData = dataFromEndpointsCombined.slice(splitIndex);

  // Function to render the table
  const renderTable = (data: DataItem[]) => (
    <div className='tableWrapper'>
      <div className='powered-by'>
        Powered by reportal.ge
      </div>
      <table className='tableClass'>
        <thead>
          <tr>
            <th>განახლდა 15 მარ, 2024</th>
            <th>საანგარიშგებო პერიოდი</th>
            <th>წინა საანგარიშგებო პერიოდი</th>
            <th>თანხობრივი ცვლილება</th>
            <th>პროცენტული ცვლილება</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const value1 = parseFloat(item.value_gel);
            const value2 = parseFloat(item.value_gel_previous);
            const difference = value1 - value2;
            const rawPercentage = ((value1 - value2) / Math.abs(value2)) * 100;
            const percentage = Number.isFinite(rawPercentage) ? `${rawPercentage.toFixed(2)}%` : "";

            return (
              <tr key={index}>
                <td>{item.LineItemGEO}</td>
                <td>{value1.toLocaleString()}</td>
                <td>{value2.toLocaleString()}</td>
                <td>{difference.toLocaleString()}</td>
                <td>{percentage}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  // Show loading indicator while data is being fetched
  if (isLoading) {
    return <div className='loader'></div>;
  }

  return (
    <div className='table-container'>
      <select className='tableSelect' value={selectedOption} onChange={handleDropdownChange}>
        <option value="" disabled selected hidden>აირჩიეთ წელი</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
      </select>

      {/* Render both tables */}
      {selectedOption && (
        <>
          {renderTable(firstHalfData)}
          {renderTable(secondHalfData)}
        </>
      )}
    </div>
  );
}

export default Table;
