import React, { useState, useEffect } from "react";
import "./Financial.css";

// Define a type for your financial data for TypeScript
export type FinancialMetric = {
  title: string;
  value: string;
  changePercentage: number;
};

type FinancialProps = {
  data: FinancialMetric[];
  selectedYear: string;
  onYearChange: (year: string) => void;
};

function Financial({ data, selectedYear, onYearChange }: FinancialProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set loading to false if data is available
    if (data.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data]);

  return (
    <div className="MainFinancial">
      <div className="FinancialYear">
        <h5 className="Fin">ფინანსური მაჩვენებლები განახლდა 2022წ</h5>
        <h5 className="Time">განახლდა: 15 მარ, 2024</h5>
      </div>
      <div className="ChooseYear">
        <select
          className="SomeYear"
          value={selectedYear}
          onChange={(e) => onYearChange(e.target.value)}
        >
          <option value="chooseYear">აირჩიე წელი</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
        </select>
      </div>

      <div className="Main-Content">
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
            <div className="SkeletonLoader" key={index}></div>
          ))
          : data.map((metric, index) => (
            <div className="fullAssets" key={index}>
              <h5 className="title">{metric.title}</h5>
              <h4 className="numbers">
                {parseFloat(metric.value) > 1
                  ? parseFloat(metric.value).toLocaleString() + " ₾"
                  : parseFloat(metric.value) > 0
                    ? metric.value + "%"
                    : metric.value + " ₾"}
              </h4>
              <h4
                className={`ExactlyNum ${metric.changePercentage > 0
                    ? "green"
                    : metric.changePercentage < 0
                      ? "red"
                      : ""
                  }`}
              >
                {metric.changePercentage}%
              </h4>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Financial;
