import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


interface DataRow {
  name: string;
  value: number;
  orgname: string;
}

interface BasicTableProps {
  selectedId: number | null;
}

interface ApiDataItem {
  LineItemGEO: string;
  value_gel: number;
  OrgNameInReport: string;
}

export default function BasicTable({ selectedId }: BasicTableProps) {
  const [rows, setRows] = useState<DataRow[]>([]);
  const [additionalColumns, setAdditionalColumns] = useState<DataRow[][]>([]);
  const [showMaxCapacityMessage, setShowMaxCapacityMessage] = useState(false);
  const BackendUrl = process.env.REACT_APP_BACKEND_URL

  useEffect(() => {
    const currentUrl = window.location.href;
    
    const companyIdRegex = /\/company\/(\d+)/;
    const match = currentUrl.match(companyIdRegex);

    if (match) {
      const companyId = match[1];

      fetch(`${BackendUrl}/compare_info/${companyId}`)
        .then(response => response.json())
        .then(data => {
          const newDataRows: DataRow[] = data.data.map((item: ApiDataItem) =>
            createData(item.LineItemGEO, item.value_gel, item.OrgNameInReport)
          );
          setRows(newDataRows);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      fetch(`${BackendUrl}/compare_info/${selectedId}`)
        .then(response => response.json())
        .then(data => {
          if (additionalColumns.length < 3) {
            const newColumn: DataRow[] = data.data.map((item: ApiDataItem) =>
              createData(item.LineItemGEO, item.value_gel, item.OrgNameInReport)
            );
            setAdditionalColumns(prevColumns => [...prevColumns, newColumn]);
          } else {
            setShowMaxCapacityMessage(true);
          }
        })
        .catch(error => console.error('Error fetching additional data:', error));
    }
  }, [selectedId]);

  function createData(name: string, value: number, orgname: string): DataRow {
    return { name, value, orgname };
  }

  const handleDeleteColumn = (index: number) => {
    setAdditionalColumns(prevColumns => prevColumns.filter((_, columnIndex) => columnIndex !== index));
    setShowMaxCapacityMessage(false); // Resetting the message after deleting a column
  };
  

  return (
    <>
      {showMaxCapacityMessage && <div style={{ textAlign: 'left', marginBottom: '10px', color: 'red' }}>შედარების რაოდენობის მაქსიმალური ლიმიტი მიღწეულია</div>}
      <TableContainer component={Paper} sx={{ maxWidth: '90%', boxShadow: 'none' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>მაჩვენებლები</TableCell>
              {rows.length > 0 &&
                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>
                  {rows[0].orgname}
                </TableCell>
              }
              {additionalColumns.map((column, index) => (
                <TableCell key={index + 1} align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>
                  {column.length > 0 && column[0].orgname}
                  {/* Delete button for additional columns */}
                  <IconButton aria-label="delete" onClick={() => handleDeleteColumn(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>
                  {row.value}
                </TableCell>
                {additionalColumns.map((column, columnIndex) => (
                  <TableCell key={columnIndex + 1} align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', fontFamily: 'AvenirNextGeorgianRegular, sans-serif' }}>
                    {column[rowIndex] ? column[rowIndex].value : 'N/A'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
