
import React from 'react';
import "./ChartUnion.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

interface FinancStateProps {
    setActiveMainIndex: (index: number) => void;
    setActiveSubIndex: (index: number) => void;
}

// ეს რად გვინდა?
const FinancState: React.FC<FinancStateProps> = ({ setActiveMainIndex, setActiveSubIndex }) => {
    const handleClickinio = () => {
        window.open("https://rustavi2.ge/", "_blank");
    }
    // 

    const handleButtonClick = (subIndex: number) => {
        setActiveMainIndex(3);
        setActiveSubIndex(subIndex);
    }

    return (
        <div>
            <div className="FinanceStatement-Container">
                <div className="FinanceStatement-Context">
                    <div className="PoweredBy">
                        <h4 className='Advertisement'>Powered by reportal.ge</h4>
                    </div>
                    <h4 className='Finance-Title'>
                        ფინანსური ანგარიშგება
                    </h4>
                    <h5 className='Finance-Info'>
                        მარტივად მოიძიე ინფორმაცია თეგეტა მოტორსის ფინანსური ანგარიშგების შესახებ
                    </h5>
                    <div className="Finance-Button">
                        <button type='submit' className='Finance-Cond' onClick={() => handleButtonClick(0)}>
                            ფინანსური მდგომარეობა
                            <span className="FaShareIcon">
                                <FontAwesomeIcon icon={faShare} style={{ transform: "translateX(5px)" }} />
                            </span>
                        </button>
                        <button type='submit' className='Finance-Cond Finance-Cond2' onClick={() => handleButtonClick(1)}>
                            საქმიანობის შედეგები
                            <span className="FaShareIcon">
                                <FontAwesomeIcon icon={faShare} style={{ transform: "translateX(5px)" }} />
                            </span>
                        </button>
                        <button type='submit' className='Finance-Way' onClick={() => handleButtonClick(2)}>
                            ფულადი სახსრების მოძრაობა
                            <span className="FaShareIcon">
                                <FontAwesomeIcon icon={faShare} style={{ transform: "translateX(5px)" }} />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinancState;
