
import 'normalize.css';
import './App.css';
import "./index.css"
import Navbar from './Components/Navbar/Navbar';
import Header from './Components/Header/Header';
import Navbar2 from './Components/Navbar2/Navbar2';
import Footer from './Components/Footer/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Startingster from './Components/StartingPage/StartingPage';
import Authorization from './Components/Authorization/Authorization';
import setupInactivityDetection from './utils/InactivityHandler';
import { useEffect } from 'react';
import MyComponent from './Components/PersonalPage/Mycomponent';
function App() {
    const handleLogout = () => {
      localStorage.removeItem('token'); // or sessionStorage.removeItem('token');
  };
    useEffect(() => {
      setupInactivityDetection(handleLogout);
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path='/' element={<Startingster />} />
          <Route path="/company/:idCode" element={
            <>
              <Navbar />
              <Header />
              <Navbar2 />
            </>
          } />
        </Routes>
      </div>
      {/* <MyComponent/> */}
    </Router>
  );
}

export default App;
