import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export interface DivisionResult {
  FVYear: number;
  division_result: number;
}

interface DivisionResultChartProps {
  data: DivisionResult[];
}

const DivisionResultChart: React.FC<DivisionResultChartProps> = ({ data }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();

      const margin = { top: 20, right: 30, bottom: 45, left: 60 };
      const width = 460 - margin.left - margin.right;
      const height = 350 - margin.top - margin.bottom;

      // Create the chart area with margins
      const chartArea = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      chartArea.append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "#f1f6ff");

      // Setup the scales
      const x = d3.scaleBand()
        .range([0, width])
        .padding(0.65);

      const y = d3.scaleLinear()
        .range([height, 0]);

      // Domain setup
      x.domain(data.map(d => d.FVYear.toString()));
      const maxY = d3.max(data, d => d.division_result) ?? 0;
      y.domain([0, maxY * 1.1]); // Ensure there's always space above the highest bar

      // Add the X Axis and style its ticks
      chartArea.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .style("font-family", "AvenirNextGeorgianRegular")
        .style("font-size", "12px")
        .style("fill", "#333");

      // Add the Y Axis with percentage format and style its ticks
      chartArea.append("g")
        .call(d3.axisLeft(y).tickFormat(d => `${d}%`))
        .selectAll("text")
        .style("font-family", "AvenirNextGeorgianRegular")
        .style("font-size", "12px")
        .style("fill", "#333");

      // Tooltip setup
      const tooltip = chartArea.append("text")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .attr("text-anchor", "middle")
        .attr("fill", "#000");

      // Plot the data with bars
      // Plot the data with bars and add data labels
chartArea.selectAll(".bar")
.data(data)
.enter().append("g")
.append("rect")
.attr("class", "bar")
.attr("x", d => x(d.FVYear.toString()) ?? 0)
.attr("width", x.bandwidth())
.attr("y", d => y(d.division_result))
.attr("height", d => height - y(d.division_result))
.attr("fill", "#656af9")
.attr("ry", 5);

// Add data labels
chartArea.selectAll(".bar-label")
.data(data)
.enter().append("text")
.attr("class", "bar-label")
.attr("x", d => x(d.FVYear.toString())! + x.bandwidth() / 2)

// .attr("x", d => x(d.FVYear.toString()) + x.bandwidth() / 2)
.attr("y", d => y(d.division_result) - 5)
.attr("text-anchor", "middle")
.style("font-family", "AvenirNextGeorgianRegular")
.style("font-size", "14px")
.style("fill", "#333")
.text(d => `${d.division_result}%`);

        
    }
  }, [data]); // Redraw chart if data changes

  return (
    <svg ref={d3Container} width={460} height={350} />
  );
}

export default DivisionResultChart;
