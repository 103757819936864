import React, { useState } from 'react';
import './Reminder.css';
import Authorization from '../Authorization/Authorization';

const Reminder: React.FC = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleButtonClick = () => {
    setIsOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className="banner-container">
      <h1>მარტივად მოიძიე 10 000-ზე მეტი კომპანიის ფინანსური ინფორმაცია</h1>
      <div className="features">
        <span>✓ ფინანსური მაჩვენებლები</span>
        <span>✓ ფინანსური ანგარიშგება</span>
        <span>✓ კომპანიების შედარება</span>
      </div>
      <button className="cta-button" onClick={handleButtonClick}>
        გაიარე ავტორიზაცია
      </button>

      {isOverlayVisible && (
        <div className="popup-overlay" onClick={handleCloseOverlay}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            <Authorization />
            <button className="close-overlay" onClick={handleCloseOverlay}>
              დახურვა
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reminder;
