import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import "./ChartUnion.css";

// Define the types for your data
interface FinancialData {
    changePercentage: number;
    title: string;
    value: number;
}

const FinancialChart: React.FC = () => {
    const [data, setData] = useState<FinancialData[]>([]);
    const [loading, setLoading] = useState(true);
    const { idCode } = useParams<{ idCode: string }>();
    const BackendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Fetch data from your API
        fetch(`${BackendUrl}/finansuri_machveneblebi/financial_highlights_top3/${idCode}?year=2021`)
            .then(response => response.json())
            .then((data: { data: FinancialData[] }) => {
                setData(data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [idCode]);

    return (
        <div>
            <div className="HalfItems">
                {loading ? (
                    // Show skeleton loader while loading
                    [1, 2].map((_, index) => (
                        <div className="SmallChart" key={index}>
                            <div className="SkeletonLoader">

                            </div>
                        </div>
                    ))
                ) : (
                    // Show actual data when loaded
                    data.map((item, index) => (
                        <div className="SmallChart" key={index}>
                            <div className="FirstItem-Title">
                                <h4 className={`Title${index === 0 ? "" : "2"}`}>{item.title}</h4>
                                <p className={`Number${index === 0 ? "" : "2"}`} style={{ fontSize: '15px' }}>{item.value.toLocaleString()} ₾</p>
                                <button style={{ fontSize: '15px' }}
                                    className={`${index === 0 ? "Percent" : "Percent2"} ${item.changePercentage > 0 ? "Positive" : "Negative"}`}
                                    type="button"
                                >
                                    {item.changePercentage}%
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default FinancialChart;
