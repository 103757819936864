import React, { useState, ReactNode, FC } from 'react';
import './Navbar2.css';
import { BsJournalText, BsBuilding } from "react-icons/bs";
import { LuNewspaper } from "react-icons/lu";
import { IoSpeedometerOutline, IoNewspaperOutline } from "react-icons/io5";
import SaqmianobaTable from '../FinansuriAngarishgeba/SaqmianobisShedegebi';
import FuladiTable from '../FinansuriAngarishgeba/FuladiSaxsrebisModzraoba';
import FinansuriTable from '../FinansuriAngarishgeba/FinansuriMdgomareoba';
import FinansuriMachveneblebi from '../FinansuriMachveneblebi/FinansuriMachveneblebi';
import SimilarCompaniesWrapper from '../SImilarCompaniesWrapper/SimilarCompaniesWrapper';
import ChartUnion from '../ComponentUnion/ChartUnion';
import HeaderNews from '../headerNews/HeaderNews';

interface MainNavItem {
  name: string;
  icon: ReactNode;
}

type SubNavComponent = FC;

const Navbar2 = () => {
  const [activeMainIndex, setActiveMainIndexState] = useState<number>(0); // Set initial active index to 0
  const [activeSubIndex, setActiveSubIndexState] = useState<number>(0);

  const setActiveMainIndex = (index: number) => {
    setActiveMainIndexState(index);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  const setActiveSubIndex = (index: number) => {
    setActiveSubIndexState(index);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  const mainNavButtons: MainNavItem[] = [
    { name: "მთავარი", icon: <span className="icon-wrapper"><BsJournalText /></span> },
    { name: "სიახლეები", icon: <span className="icon-wrapper"><LuNewspaper /></span> },
    { name: "ფინანსური მაჩვენებლები", icon: <span className="icon-wrapper"><IoSpeedometerOutline /></span> },
    { name: "ფინანსური ანგარიშგება", icon: <span className="icon-wrapper"><IoNewspaperOutline /></span> },
    { name: "მსგავსი კომპანიები", icon: <span className="icon-wrapper"><BsBuilding /></span> },
  ];

  const subNavButtons: string[] = [
    "ფინანსური მდგომარეობა",
    "საქმიანობის შედეგები",
    "ფულადი სახსრების მოძრაობა"
  ];

  const MainNavComponents: { [key: number]: JSX.Element | null } = {
    0: <ChartUnion setActiveMainIndex={setActiveMainIndex} setActiveSubIndex={setActiveSubIndex} />, // Pass setActiveMainIndex and setActiveSubIndex to ChartUnion
    1: <HeaderNews />,
    2: <FinansuriMachveneblebi />,
    4: <SimilarCompaniesWrapper />,
  };

  const SubNavComponents: { [key: number]: SubNavComponent } = {
    0: FinansuriTable,
    1: SaqmianobaTable,
    2: FuladiTable
  };

  const handleMainNavClick = (index: number) => {
    if (index === 3) {
      setActiveMainIndex(index); // Set the active main index to "ფინანსური ანგარიშგება"
      setActiveSubIndex(0); // Set the active sub index to "ფინანსური მდგომარეობა"
    } else {
      setActiveMainIndex(index);
      setActiveSubIndex(0); // Set the active sub index to 0 when it's not sub-navigation
    }
  };

  const renderMainNavComponent = () => {
    // Check if activeMainIndex is not null and not the special index for sub-navigation (3 in this case)
    if (activeMainIndex !== null && activeMainIndex !== 3) {
      const Component = MainNavComponents[activeMainIndex];
      // Ensure the component exists before attempting to render it
      return Component || null;
    } else if (activeMainIndex === 3) {
      // Handle the sub-navigation logic for activeMainIndex === 3
      const SubNavComponent = activeSubIndex !== null ? SubNavComponents[activeSubIndex] : undefined;
      return SubNavComponent ? <SubNavComponent /> : null;
    }

    return null; // Return null if no conditions are met
  };

  return (
    <div>
      <nav className="main-nav" style={{ marginBottom: '100px' }}>
        {mainNavButtons.map((button, index) => (
          <button
            key={index}
            className={`nav-btn ${activeMainIndex === index ? 'active' : ''}`}
            onClick={() => handleMainNavClick(index)}
          >
            {button.icon} {button.name}
          </button>
        ))}
      </nav>
      {activeMainIndex === 3 ? (
        <nav className="sub-nav">
          {subNavButtons.map((name, index) => (
            <button
              key={index}
              className={`nav-btn ${activeSubIndex === index ? 'active' : ''}`}
              onClick={() => setActiveSubIndex(index)}
            >
              {name}
            </button>
          ))}
        </nav>
      ) : null}
      {renderMainNavComponent()}
    </div>
  );
};

export default Navbar2;
