import React, { useState, useEffect } from 'react';
import './HeaderNews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface NewsImage {
    boxed: string;
    boxed_thumbnail: string;
    wide: string;
    wide_thumbnail: string;
}

interface NewsItem {
    id: number;
    images: NewsImage;
    publish_date: string;
    title: string;
    url: string;
}

function HeaderNews() {
    const [newsData, setNewsData] = useState<NewsItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const BackendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Check if the data is already cached
        const cachedNewsData = sessionStorage.getItem('newsData');

        if (cachedNewsData) {
            // If cached data exists, parse and use it
            setNewsData(JSON.parse(cachedNewsData));
            setLoading(false);
        } else {
            // If no cached data, fetch from API
            fetch(`${BackendUrl}/bm_ge/get-all-news`)
                .then(response => response.json())
                .then(data => {
                    setNewsData(data.data);
                    sessionStorage.setItem('newsData', JSON.stringify(data.data)); // Cache the data in sessionStorage
                    setLoading(false);
                })
                .catch(error => {
                    setError('Failed to load news. Please try again later.');
                    setLoading(false);
                    console.error('Error fetching news:', error);
                });
        }
    }, [BackendUrl]);

    const handleNewsClick = (url: string) => {
        window.open(url, '_blank');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div className="MainContainer-HeaderNews">
                <div className="advertising">
                    <h5 className='bmge'>Powered by bm.ge</h5>
                </div>

                <div className="CompanyNews-HeaderNews">
                    <h3 className='Title-HeaderNews'>კომპანიის <span className='PartTitle-HeaderNews'>სიახლეები</span></h3>
                </div>

                <div className="SortingPositional">
                    <select className='Sorting-HeaderNews'>
                        <option value="Sorting">სორტირება</option>
                        <option value="2022">2022</option>
                    </select>
                </div>

                <div className="AllContent-HeaderNews">
                    {newsData.map((newsItem) => (
                        <div
                            key={newsItem.id}
                            className="MainNews-HeaderNews"
                            onClick={() => handleNewsClick(newsItem.url)}
                        >
                            <img
                                src={newsItem.images.boxed_thumbnail}
                                alt={newsItem.title}
                                className='imigi'
                            />
                            <h5 className='NewsTitle'>
                                {newsItem.title}
                            </h5>
                            <h5 className="Media-HeaderNews">Business Media</h5>
                            <h5 className='TimeAndDate'>{new Date(newsItem.publish_date).toLocaleDateString('ka-GE', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })}</h5>
                            <h5 className='Full2'>ვრცლად <span className='fonticon'><FontAwesomeIcon icon={faChevronRight} /></span></h5>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HeaderNews;
