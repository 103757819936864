import React, { useEffect, useState } from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import "./LastView.css";
import building from "../../images/building.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
// import CompareOverlay from './CompareOverlay';
import CompareOverlay from "../Shedareba/CompareOverlay";
import { Link } from "react-router-dom";

interface CustomJwtPayload extends JwtPayload {
    user_id: string;
}

interface LastSeenItem {
    idCode: string;
    OrgNameInReport: string;
}

function LastView() {
    const [lastSeen, setLastSeen] = useState<LastSeenItem[]>([]);
    const [showOverlay, setShowOverlay] = useState(false);
    const AuthUrl = process.env.REACT_APP_AUTHAPI_URL;

    useEffect(() => {
        async function fetchLastSeen() {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const decodedToken = jwtDecode<CustomJwtPayload>(token);
                    const userId = decodedToken.user_id;

                    const response = await fetch(`${AuthUrl}/get_last_seen_info?userId=${userId}`);
                    const data = await response.json();
                    if (response.ok) {
                        setLastSeen(data.last_seen);
                        console.log(data.last_seen);
                    } else {
                        console.error(data.error);
                    }
                } else {
                    console.error('No JWT token found in localStorage');
                }
            } catch (error) {
                console.error('Error fetching last seen info:', error);
            }
        }

        fetchLastSeen();
    }, []);

    const saveCompany = async (idcode: string, OrgNameInReport: string) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const response = await fetch(`${AuthUrl}/save_private_page_saved_companies`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        userId: jwtDecode<CustomJwtPayload>(token).user_id,
                        idCode: idcode,
                        OrgNameInReport: OrgNameInReport
                    })
                });

                const data = await response.json();
                if (response.ok) {
                    console.log('Company saved successfully:', data.message);
                } else {
                    console.error(data.error);
                }
            } else {
                console.error('No JWT token found in localStorage');
            }
        } catch (error) {
            console.error('Error saving company:', error);
        }
    };

    const toggleOverlay = () => {
        setShowOverlay(!showOverlay);
    };

    return (
        <div className="LastView-Container">
            <div className="LastViewHeader-Title">
                <h4 className="LastView">ბოლოს ნანახი</h4>
            </div>

            {lastSeen.map((item, index) => (
                <div key={index} className={index === 0 ? "LastView-MainRow" : "LastView-OrdinaryRow"}>
                    <img src={building} alt="BOG LOGO" className={index === 0 ? "FIRSTROW-BOG" : "Ordinary-BOG"} />

                    <h4 className={index === 0 ? "BOGNAME" : "BOGNAMEE"}>
                        <Link to={`/company/${item.idCode}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {item.OrgNameInReport}
                        </Link>
                    </h4>

                    <div className="together">
                        <h4 className="CompereItem" onClick={toggleOverlay}>
                            <span className="ArrowIcons">
                                <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ transform: "translateX(-10px)" }} />
                            </span>
                            შედარება
                        </h4>

                        <h4 className="SaveItem" onClick={() => saveCompany(item.idCode, item.OrgNameInReport)}>
                            <span className="SaveIcon">
                                <FontAwesomeIcon icon={faPlus} style={{ color: "#656AF9", transform: "translateX(-10px)", marginTop: "-2.4rem" }} />
                            </span>
                            შენახვა
                        </h4>

                        <hr className="last-seen-border-color" />
                    </div>
                </div>
            ))}

            {showOverlay && <CompareOverlay onClose={toggleOverlay} />}
        </div>
    );
}

export default LastView;
