import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Navbarnew.css';
import ReportX from '../../images/ReportX.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Authorization from '../Authorization/Authorization';
import MyComponent from '../PersonalPage/Mycomponent';

interface Suggestion {
  id: number;
  name: string;
}

function Navbar() {
  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfilePage, setShowProfilePage] = useState(false); // New state for showing profile page
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const profilePageRef = useRef<HTMLDivElement>(null); // Ref for the profile page
  const BackendUrl = process.env.REACT_APP_BACKEND_URL;
  const FrontendUrl = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const saveSearchQuery = async (inputValue: string): Promise<void> => {
    try {
      await axios.post(`${BackendUrl}/save_query`, { query: inputValue });
    } catch (error) {
      console.error('Error saving search query:', error);
    }
  };

  const fetchSuggestions = async (inputValue: string): Promise<void> => {
    if (inputValue.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      setLoading(true);
      const response = await axios.get<Suggestion[]>(`${BackendUrl}/search?query=${inputValue}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setLoading(false);
    }
  };

  const debounce = <F extends (...args: any[]) => any>(func: F, wait: number): ((...args: Parameters<F>) => void) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return (...args: Parameters<F>) => {
      const later = () => {
        timeout = null;
        func(...args);
      };
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(later, wait);
    };
  };

  const fetchSuggestionsDebounced = useCallback(debounce(fetchSuggestions, 1000), []);
  const saveSearchQueryDebounced = useCallback(debounce(saveSearchQuery, 1000), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setSuggestions([]); // Clear previous suggestions
    fetchSuggestionsDebounced(inputValue);
    saveSearchQueryDebounced(inputValue);
  };

  const handleSuggestionClick = (idCode: number) => {
    window.location.href = `${FrontendUrl}/company/${idCode}`;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (profilePageRef.current && !profilePageRef.current.contains(event.target as Node)) {
      setShowProfilePage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileClick = () => {
    setShowProfilePage(true);
  };

  return (
    <>
      <nav className="navbar-second">
        <div className="navbar-search" ref={suggestionsRef}>
          <div className='logopositional'>
            <a href={FrontendUrl} className="navbar-logo-second">
              <img src={ReportX} alt="Logo" />
            </a>
          </div>
          <input
            type="text"
            placeholder="ძებნა"
            value={query}
            onChange={handleChange}
          />
          <div className="loading-animation-container">
            {loading && <div className="loading-animation"></div>}
          </div>
          <ul className={`suggestions ${suggestions.length === 0 ? 'suggestions-empty' : ''}`}>
            {suggestions.map((suggestion) => (
              <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion.id)}>
                {suggestion.name}
              </li>
            ))}
          </ul>
        </div>

        {/* right side */}
        <div className="navbar-login-second">
          <div className='LineMainPage-second'>
            <FontAwesomeIcon icon={faPhone} className='Faphone-second' />
            <h6 className='hotline-second'>ცხელი ხაზი<span className='split-second'> 16 111</span></h6>
          </div>

          {isLoggedIn ? (
            <a href="#" onClick={handleProfileClick} className="navbar-profile-second">
              <FontAwesomeIcon icon={faUser} className='FaUser-second' />
              პირადი გვერდი
            </a>
          ) : (
            <button onClick={togglePopup} className="authorization-second">ავტორიზაცია</button>
          )}
        </div>
      </nav>

      {/* ნავბარი სრულდება უკვე  */}

      {isPopupVisible && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            <Authorization />
          </div>
        </div>
      )}

      {showProfilePage && (
        <div className="my-component-overlay" ref={profilePageRef} onClick={e => e.stopPropagation()}>
          <MyComponent setShowProfilePage={setShowProfilePage} />
        </div>
      )}
    </>
  );
}

export default Navbar;
