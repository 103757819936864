import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import Tegeta from "../../images/Tegeta.png";
import "./Header.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPaperclip, faBuilding, faArrowsLeftRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import Compare from '../Shedareba/Compare';
import SkeletonHeader from './SkeletonHeader';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import korpusi from '../../images/building.svg';

interface CompanyInfo {
  OrgNameInReport: string;
}

interface DecodedToken extends JwtPayload {
  user_id: string;
}

function Header() {
  const { idCode } = useParams<{ idCode: string }>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo[]>([]);
  const [showCompare, setShowCompare] = useState(false);
  const [copyMessage, setCopyMessage] = useState("ბმულის კოპირება");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [saveMessage, setSaveMessage] = useState("შენახვა");
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [showSaveIcon, setShowSaveIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const AuthUrl = process.env.REACT_APP_AUTHAPI_URL
  const BackendUrl = process.env.REACT_APP_BACKEND_URL

  useEffect(() => {
    fetchCompanyInfo();
  }, [idCode]);

  useEffect(() => {
    if (companyInfo.length > 0) {
      saveCompanyInfo();
    }
  }, [companyInfo]);

  const fetchCompanyInfo = () => {
    axios.get(`${BackendUrl}/retrieve_company_info/${idCode}`)
      .then(response => {
        setCompanyInfo(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const getTokenData = (): string | null => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode<DecodedToken>(token);
        return decodedToken.user_id;
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
    return null;
  };

  const saveCompanyInfo = () => {
    const userId = getTokenData();
    if (!userId) {
      console.error('User ID not found');
      return;
    }
    const payload = {
      userId,
      idCode,
      OrgNameInReport: companyInfo[0]?.OrgNameInReport
    };
    axios.post(`${AuthUrl}/save_last_seen_info`, payload)
      .then(response => {
        console.log('Data saved successfully:', response);
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };

  const toggleCompare = () => {
    setShowCompare(!showCompare);
  };

  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === overlayRef.current) {
      toggleCompare();
    }
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopyMessage("დაკოპირებულია!");
        setShowCopyMessage(true);
        setTimeout(() => {
          setCopyMessage("ბმულის კოპირება");
          setShowCopyMessage(false);
        }, 2000); // Hide message after 3 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleSave = () => {
    const userId = getTokenData();
    if (!userId) {
      console.error('User ID not found');
      return;
    }
    const payload = {
      userId,
      idCode,
      OrgNameInReport: companyInfo[0]?.OrgNameInReport
    };
    axios.post(`${AuthUrl}/save_private_page_saved_companies`, payload)
      .then(response => {
        setSaveMessage("შენახულია!");
        setShowSaveMessage(true);
        setShowSaveIcon(true);
        setTimeout(() => {
          setSaveMessage("შენახვა");
          setShowSaveMessage(false);
          setShowSaveIcon(false);
        }, 3000); // Hide message after 3 seconds
      })
      .catch(error => {
        console.error('Error saving company:', error);
      });
  };

  if (loading) {
    return <SkeletonHeader />; // Render the loader while data is being fetched
  }

  return (
    <div className='MainNavbar'>
      <div className='NavbarInfo'>

        {/* აქ ჩაისმება რაღაც სირლქს და მის შიგნით ჩაისმება აიქონი */}

        <div className='HeaderCircle-Icon'>
          <img src={korpusi} className='building-icon' alt="building" />
        </div>

        <h5 className='organisation'><FontAwesomeIcon icon={faBuilding} className='fa-building' />ორგანიზაცია</h5>
        {companyInfo && companyInfo.length > 0 && (
          <h2 className='Tegetatitle'>{companyInfo[0].OrgNameInReport}</h2>
        )}
        {/* <button type="submit" className='Submit' onClick={toggleCompare}>
          <FontAwesomeIcon icon={faArrowsLeftRight} className="fa-arrows-left-right" />
          შედარება
        </button> */}
        <div className='power'>

          <div className="copy-container" onClick={copyLinkToClipboard}>
            <FontAwesomeIcon icon={showCopyMessage ? faCheck : faPaperclip} className={`fa-paperclip ${showCopyMessage ? 'fa-check' : ''}`} />
            <h4 className={`links ${showCopyMessage ? 'copy-success' : ''}`}>{copyMessage}</h4>
          </div>
          <FontAwesomeIcon icon={showSaveIcon ? faCheck : faPlus} className='sevendeys' />
          <h4 className={`Saving ${showSaveMessage ? 'save-success' : ''}`} onClick={handleSave}>{saveMessage}</h4>
        </div>
      </div>
      {showCompare && (
        <div ref={overlayRef} onClick={handleOverlayClick} className="overlay">
          <Compare />
        </div>
      )}
    </div>
  );
}

export default Header;
