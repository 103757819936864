// inactivityHandler.ts
let inactivityTimeout: ReturnType<typeof setTimeout>;

const INACTIVITY_TIME_LIMIT = 15 * 60 * 1000; 
// const INACTIVITY_TIME_LIMIT = 5 * 1000; 

const resetInactivityTimeout = (logoutFunction: () => void) => {
    // clearTimeout(inactivityTimeout);
    // inactivityTimeout = setTimeout(logoutFunction, INACTIVITY_TIME_LIMIT);
};

const setupInactivityDetection = (logoutFunction: () => void) => {
    const events: (keyof WindowEventMap)[] = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    
    const resetTimeout = () => resetInactivityTimeout(logoutFunction);

    events.forEach(event => {
        window.addEventListener(event, resetTimeout);
    });

    resetInactivityTimeout(logoutFunction);
};

export default setupInactivityDetection;
