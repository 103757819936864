import React from 'react';
import './SkeletonHeader.css';

const SkeletonHeader: React.FC = () => {
  return (
    <div className="skeleton-loader">
      <div className="skeleton-logo"></div>
      <div className="skeleton-details">
        <div className="skeleton-title"></div>
        <div className="skeleton-text-group">
          <div className="skeleton-subtitle"></div>
          <div className="skeleton-button"></div>
        </div>
      </div>
      <div className="skeleton-links">
        <div className="skeleton-link"></div>
        <div className="skeleton-link"></div>
      </div>
    </div>
  );
};

export default SkeletonHeader;
