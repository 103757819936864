import React from "react";
import "./NavbarSimilarCompanies.css";
// Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faPlus, faSquareCaretUp, faSquareCaretDown, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";

// Import Images
import building from "../../images/building.jpg";


function NavbarSimilarCompanies() {
    return (
        <div className="BoxContainers">
            {/* მოხდა გადაკეთება აქ */}
            <div className="navbarContent-SimCompanies">

                {/* მოხდა გადაკეთება აქ */}
                <div className="SearchingResult-SimCompanies">
                    <h3 className="Search-Sim2">მსგავსი კომპანიები</h3>
                    <h5 className="Update-Time">განახლდა: 5 სექტემბერს, 2024 </h5>
                    <h4 className="Name-Sim">დასახელება</h4>
                </div>

                <div className="List2">
                    <div className="FullActive-Sim">
                        <h5 className="Active-Sim">მთლიანი აქტივები</h5>
                    </div>

                    <div className="Responsible-Sim">
                        <h5 className="FullResponsible-Sim"><span className="breakAfter">მთლიანი</span>ვალდებულებები</h5>
                    </div>

                    <div className="FullCapital-Sim">
                        <h5 className="Capital-Sim">მთლიანი კაპიტალი</h5>
                    </div>
                    <div className="FullIncome-Sim">
                        <h5 className="Income-Sim"><span className="breakAfter">მთლიანი</span>შემოსავალი</h5>
                    </div>
                    <div className="ClearWin-Sim">
                        <h5 className="Win-Sim">წმინდა მოგება</h5>
                    </div>

                </div>

                <select className="simple-select">
                    <option value="option1">სორტირება</option>
                    <option value="option3">2022</option>
                </select>
                {/* Starting Row */}

                <div className="HorizontalLineXBog">
                    <div className="CompanyContext">
                        <img src={building} className="BogImg" alt="This is Company logo"></img>
                        <h4 className="CompanyMainTitle">საქართველოს ბანკი</h4>
                        <h6 className="SeeCompany"><span className="CustomIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" className="upArrow" fill="currentColor"><path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path></svg>
                        </span>
                            ნახვა</h6>
                    </div>
                </div>

                <div className="ActiveNumber">
                    <h4 className="Num">1,635,405</h4>
                    <h4 className="Percent-sim">8.6%</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn2" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
                </div>
                <div className="RestOfAllNumber">
                    <h4 className="Num">1,444,405</h4>
                    <h4 className="PercentGreen">4.4%</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
                </div>
                <div className="RestOfAllNumber">
                    <h4 className="Num">1,444,405</h4>
                    <h4 className="PercentGreen">4.4%</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
                </div>
                <div className="RestOfAllNumber">
                    <h4 className="Num">1,444,405</h4>
                    <h4 className="PercentGreen">4.4%</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
                </div>
                <div className="RestOfAllNumber">
                    <h4 className="Num">1,444,405</h4>
                    <h4 className="PercentGreen">4.4%</h4>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" className="InfoBtn" fill="rgba(146, 152, 166, 1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"></path></svg>
                </div>


                {/* <div className="Compere-Saving">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17" fill="rgba(101, 106, 249, 1)"><path d="M16.0503 12.0498L21 16.9996L16.0503 21.9493L14.636 20.5351L17.172 17.9988L4 17.9996V15.9996L17.172 15.9988L14.636 13.464L16.0503 12.0498ZM7.94975 2.0498L9.36396 3.46402L6.828 5.9988L20 5.99955V7.99955L6.828 7.9988L9.36396 10.5351L7.94975 11.9493L3 6.99955L7.94975 2.0498Z"></path></svg>
                    <h4 className="CompereNavbar">შედარება</h4>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23" className="PlusNavbar" fill="rgba(101, 106, 249, 1)"><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg>
                    <h4 className="SavingNavbar">შენახვა</h4>
                </div> */}

                {/* Second */}

















































            </div>

        </div>
    )
}




export default NavbarSimilarCompanies;
















