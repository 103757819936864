import React, { useState, useEffect, useRef } from 'react';
import './NavbarMainPage.css';
import ReportX from '../../images/ReportX.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import Authorization from '../Authorization/Authorization';
import MyComponent from '../PersonalPage/Mycomponent'; // Assuming MyComponent is located here

function NavbarMainPage() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfilePage, setShowProfilePage] = useState(false); // State to manage profile page visibility
  const profilePageRef = useRef<HTMLDivElement>(null); // Ref for the profile page
  const FrontendUrl = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleProfileClick = () => {
    setShowProfilePage(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (profilePageRef.current && !profilePageRef.current.contains(event.target as Node)) {
      setShowProfilePage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <>
      <nav className="navbars">
        <div className="navbar-content">
          <a href={FrontendUrl} className="navbar-logo">
            {<img src={ReportX} alt="Logo" />}
          </a>
          <div className='LineMainPage'>
            <FontAwesomeIcon icon={faPhone} className='Faphone' />
            <h6 className='hotline'>ცხელი ხაზი<span className='split'> 16 111</span></h6>
          </div>

          <div className="navbar-login-MainPage">
            {isLoggedIn ? (
              <a href="#" onClick={handleProfileClick} className="navbar-profile">
                <FontAwesomeIcon icon={faUser} className='FaUser' />
                პირადი გვერდი
              </a>
            ) : (
              <button className="authorizationmainpage" onClick={togglePopup}>ავტორიზაცია</button>
            )}
          </div>
        </div>
      </nav>

      {isPopupVisible && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            <Authorization />
          </div>
        </div>
      )}

      {showProfilePage && (
        <div className="my-component-overlay" ref={profilePageRef} onClick={e => e.stopPropagation()}>
          <MyComponent setShowProfilePage={setShowProfilePage} />
        </div>
      )}
    </>
  );
}

export default NavbarMainPage;
